import React from "react";
import { Images } from "../assets";
import { Link } from "react-router-dom";
import {
  getTimeAgo,
  truncateMiddleAddress,
  truncateTxHash,
} from "../utils/helper";
import { Transaction } from "../interfaces";
import { LatestBlockOrTransactionSkeleton } from "./skeleton/Index";
import { CHAIN_LOGOS, CHAINS_EXPLORER } from "../utils/contant";

interface LatestTransactionsProps {
  transactions: Transaction[];
  isLoading: boolean;
}

const LatestTransactions: React.FC<LatestTransactionsProps> = ({
  transactions,
  isLoading,
}) => {
  return (
    <div className="flex flex-col justify-between bg-backgroundLight text-primaryLight rounded-xl p-6 max-w-2xl font-ModAberMano border border-borderBackgroundLight ">
      <div className="flex justify-between items-center pb-4 border-b border-borderBackgroundLight">
        <h2 className="text-lg font-semibold">Latest transactions</h2>
        <Link to="/txs">
          <button className="text-sm text-primaryLight hover:underline">
            View All transactions
          </button>
        </Link>
      </div>
      <ul className="divide-y divide-borderBackgroundLight border-b border-borderBackgroundLight mt-2 ">
        {isLoading ? (
          <LatestBlockOrTransactionSkeleton />
        ) : (
          transactions.slice(0, 5).map((transaction) => {
            // Find the remoteChainId in the decoded input
            const remoteChainIdParam =
              transaction?.decodedInput?.parameters?.find(
                (item) => item.name === "remoteChainId"
              );

            // Ensure remoteChainIdParam exists and has a value
            const remoteChainIdValue = remoteChainIdParam?.value;

            const explorerLink =
              typeof remoteChainIdValue === "string" &&
              remoteChainIdValue in CHAINS_EXPLORER
                ? CHAINS_EXPLORER[
                    remoteChainIdValue as keyof typeof CHAINS_EXPLORER
                  ]
                : undefined;
            const explorerImg =
              typeof remoteChainIdValue === "string" &&
              remoteChainIdValue in CHAIN_LOGOS
                ? CHAIN_LOGOS[remoteChainIdValue as keyof typeof CHAIN_LOGOS]
                : undefined;

            return (
              <li
                key={transaction.hash}
                className="py-4 flex items-center justify-between"
              >
                <div className="flex items-center space-x-4">
                  <div className="w-10 h-10  bg-[#00000040] rounded-full flex items-center justify-center">
                    <img src={Images.frmLogo} alt="transaction" className="" />
                  </div>
                  <div>
                    <Link to={`/tx/${transaction.hash}`}>
                      <div className="text-primary font-ModAberMano cursor-pointer">
                        {truncateTxHash(transaction.hash, 13)}
                      </div>
                    </Link>
                    <div className="text-xs text-primaryLight flex items-center gap-3">
                      <span>{getTimeAgo(transaction.timestamp)}</span>
                      <span className="bg-backgroundDark px-3 py-1 rounded-md">
                        {transaction.fee}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center self-end">
                    <span className="text-primaryLight text-[13px] font-normal">
                      Source:
                    </span>
                    <div className="flex ml-4 items-center gap-2">
                      <img className="w-4 h-4" src={explorerImg} alt="" />
                      <a
                        target="_blank"
                        href={`${explorerLink}address/${transaction.sourceMsgSender}`}
                        rel="noopener noreferrer"
                        className="flex items-center gap-2"
                      >
                        <span className="text-sm">
                          {truncateMiddleAddress(
                            transaction?.sourceMsgSender,
                            8,
                            8
                          )}
                        </span>
                        <img src={Images.explorerIcon} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="flex items-center self-end">
                    <span className="text-primaryLight text-[13px] font-normal">
                      Composer:
                    </span>
                    <div className="flex ml-4 items-center gap-2">
                      <img
                        className="w-4 h-4"
                        src={Images.frmLogoColorIcon}
                        alt=""
                      />
                      <Link
                        to={`/address/${transaction.remoteContract}`}
                        rel="noopener noreferrer"
                        className="flex items-center gap-2"
                      >
                        <span className="text-sm">
                          {truncateMiddleAddress(
                            transaction?.remoteContract,
                            8,
                            8
                          )}
                        </span>
                        <img src={Images.explorerIcon} alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            );
          })
        )}
      </ul>
      <Link to="/txs">
        <div className="flex items-center justify-center mt-4 text-center">
          <img src={Images.plusIcon} alt="" />
          <span className="text-center hover:underline cursor-pointer">
            View All Transactions
          </span>
        </div>
      </Link>
    </div>
  );
};

export default LatestTransactions;
