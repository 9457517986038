import React, { useState } from "react";
import { Images, ReactIcons } from "../assets";

interface ContractAddressHeaderProps {
  title: string;
  address: string;
}

const ContractAddressHeader: React.FC<ContractAddressHeaderProps> = ({
  title,
  address,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000); // Show tick mark for 1 second
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="flex items-center gap-2 font-ModAberMano">
      <img src={Images.qpLogoIcon} alt="" />
      <span className="font-medium text-lg text-primaryLight">
        {title}: {address}
      </span>
      <div className="flex items-center gap-1 ml-4">
        {copied ? (
          <ReactIcons.IoCheckmark
            className="w-5 h-5 cursor-pointer text-primaryLight"
            onClick={handleCopyClick}
          />
        ) : (
          <ReactIcons.IoCopyOutline
            className="w-5 h-5 cursor-pointer text-primaryLight"
            onClick={handleCopyClick}
          />
        )}

        {/* <img
          className="w-5 h-5 cursor-pointer"
          src={copied ? Images.copyCheckMark : Images.copyIcon}
          alt="Copy Icon"
          onClick={handleCopyClick}
        /> */}
        <img
          className="w-5 h-5 ml-1 cursor-pointer"
          src={Images.qrIcon}
          alt="QR Icon"
        />
      </div>
    </div>
  );
};

export default ContractAddressHeader;
