import React from "react";
import MainLayout from "../layout/MainLayout";
import SearchBar from "../components/SearchBar";
import { Images } from "../assets";

const AccountSettings: React.FC = () => {
  return (
    <MainLayout>
      <SearchBar />

      <div className="flex items-center justify-between px-2 ">
        <div className="flex items-center gap-3">
          <img src={Images.qpLogoIcon} alt="" />
          <span className="text-primaryLight text-lg font-semibold ">
            Personal Info
          </span>
        </div>
        <div className="cursor-pointer flex items-center gap-2 px-4 py-2 rounded-md bg-backgroundLight">
          <img src={Images.signoutIcon} alt="" />
          <span className="text-primaryLight text-lg font-semibold">
            Sign Out
          </span>
        </div>
      </div>
      <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row justify-between gap-10 my-10">
        <div className="p-4 border border-borderBackgroundLight rounded-[20px] font-ModAberMano text-primaryLight flex-1 bg-backgroundLight">
          <h3 className="font-semibold text-lg  font-ModAberMano border-b border-borderBackgroundLight pb-2">
            personal Info
          </h3>
          <ul className="mt-4 space-y-4">
            <li className="font-medium">
              YOUR USERNAME:{" "}
              <span className="font-normal">ferrumhavethebestteam</span>
            </li>
            <li className="font-medium">
              YOUR EMAI ADDRESS:{" "}
              <span className="font-normal">name@domain.com</span>
            </li>
            <li className="font-medium">
              LAST LOGIN:{" "}
              <span className="font-normal">2023-03-10 10:35:19 (UTC)</span>
            </li>
            <li className="font-medium">
              NEWSLETTER:{" "}
              <span className="font-normal">Subscribe to Newsletter</span>
            </li>
          </ul>
        </div>

        {/* second card  */}
        <div className="p-4 border border-borderBackgroundLight rounded-[20px] font-ModAberMano text-primaryLight flex-1 bg-backgroundLight">
          <h3 className="font-semibold text-lg  font-ModAberMano border-b border-borderBackgroundLight pb-2">
            Password
          </h3>
          <ul className="mt-4 space-y-4">
            <li className="font-medium flex items-center ">
              <label htmlFor="password" className="w-2/5">
                Enter OLD password:
              </label>
              <input
                id="password"
                type="password"
                className="ml-auto p-2 text-primaryLight focus:outline-none bg-backgroundDark w-3/5 rounded-md "
                placeholder="********"
              />
            </li>
            <li className="font-medium flex items-center ">
              <label htmlFor="password" className="w-2/5">
                Enter NEW password:
              </label>
              <input
                id="password"
                type="password"
                className="ml-auto p-2 text-primaryLight focus:outline-none bg-backgroundDark w-3/5 rounded-md "
                placeholder="********"
              />
            </li>
            <li className="font-medium flex items-center ">
              <label htmlFor="password" className="w-2/5">
                Re-Confirm password:
              </label>
              <input
                id="password"
                type="password"
                className="ml-auto p-2 text-primaryLight focus:outline-none bg-backgroundDark w-3/5 rounded-md "
                placeholder="********"
              />
            </li>
          </ul>
          <div className="  flex items-center justify-end my-2  ">
            <button className="cursor-pointer bg-primary px-4 py-2 rounded-md text-primaryContrast font-semibold">
              save changes
            </button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default AccountSettings;
