import React, { useState } from "react";
import InputField from "../../components/InputField";
import { Button } from "../../components/ui/Button";
// import { Button } from "../../components/ui/Button";

const StakeActions: React.FC = () => {
  const [delegateAddress, setDelegateAddress] = useState("");
  const [stakeAmount, setStakeAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");

  return (
    <div className="p-6 bg-backgroundLight rounded-[20px] text-primaryLight max-w-[550px]">
      <h2 className="text-lg font-semibold mb-4 pb-1 border-b border-borderBackgroundLight">
        Stake Actions
      </h2>
      <div className="space-y-4">
        <div className=" w-full space-y-1">
          <label className=" mb-1 text-sm">Miner Operator</label>
          <div className="flex items-center bg-backgroundDark py-1 rounded-md  ">
            <input
              type="text"
              className="px-4 bg-backgroundDark w-[80%]   text-primaryLight  focus:outline-none  "
              placeholder="Address"
            />
            <Button
              title="ASSIGN - ONLY MINER"
              variant="secondary"
              className="!text-xs !px-2 !py-3 w-[190px]"
            />
          </div>
        </div>

        <div className=" w-full space-y-1">
          <label className=" mb-1 text-sm">Delegate</label>
          <div className="flex justify-between items-center bg-backgroundDark py-3 rounded-md  ">
            <input
              type="text"
              className="px-4 bg-backgroundDark w-[68%] text-primaryLight focus:outline-none  "
              placeholder="Address"
            />
          </div>
        </div>

        <div className=" w-full space-y-1">
          <label className=" mb-1 text-sm">Stake FRM</label>
          <div className="flex items-center bg-backgroundDark py-1 rounded-md  ">
            <input
              type="text"
              className="px-4 bg-backgroundDark w-[80%] text-primaryLight focus:outline-none"
              placeholder="Amount to Stake"
            />
            <Button
              title="APPROVE"
              variant="secondary"
              className="!text-xs !px-5 !py-3 w-[190px]"
            />
          </div>
        </div>
        <div className=" w-full space-y-1">
          <label className=" mb-1 text-sm">Amount</label>
          <div className="flex items-center bg-backgroundDark py-1 rounded-md  ">
            <input
              type="text"
              className="px-4 bg-backgroundDark w-[80%] text-primaryLight focus:outline-none"
              placeholder="Amount"
            />
            <Button
              title="Withdraw"
              variant="secondary"
              className="!text-xs !px-5 !py-3 w-[190px]"
            />
          </div>
        </div>

        <div className="mt-4 w-full">
          <Button
            variant="primary"
            title="Collect Withdrawal Items"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default StakeActions;
