import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchSearchResults = async (query: any) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/explorer/search?data=${
    query?.data ? query.data : ""
  }&fromNetwork=${query?.fromNetwork ? query.fromNetwork : ""}&toNetwork=${
    query?.toNetwork ? query.toNetwork : ""
  }&type=${query?.type ? query.type : ""}&fromDate=${
    query?.fromDate ? query.fromDate : ""
  }&toDate=${query?.toDate ? query.toDate : ""}`;

  const { data } = await axios.get(url);
  return data.response ?? data;
};

export const useSearchResults = (query: any, shouldSearch: boolean) => {
  return useQuery({
    queryKey: ["searchResults", query],
    queryFn: () => fetchSearchResults(query),
    // staleTime: 10 * 1000,
    enabled: !!query && shouldSearch, // Only enable query if shouldSearch is true and query is non-empty
  });
};
