import React from "react";
import { Images } from "../assets";

const Footer: React.FC = () => {
  return (
    <footer className="font-ModAberMano">
      <div className="max-w-[1382px] mx-auto border-t-2 border-primary pt-4">
        <div className="flex flex-col md:flex-row justify-between items-center pb-[3rem] md:pb-20 ">
          <div className="flex flex-col ">
            <a href="/">
              <img src={Images.footerLogo} alt="" />
            </a>
            <p className="text-primaryLight py-2 rounded-md text-[10px] font-normal">
              Quantum Portal Explorer is a Block Explorer and Analytics Platform
              for Ferrum Network,
            </p>
          </div>

          <div className="ml-10 flex flex-wrap space-x-4">
            <a
              target="_blank"
              href="https://bridge.ferrum.network/frm"
              className="text-primaryLight hover:text-secondary px-3 py-2  text-base font-normal"
            >
              Bridge
            </a>

            <a
              target="_blank"
              href="https://gateway.ferrumnetwork.io/"
              className="text-primaryLight hover:text-secondary px-3 py-2  text-base font-normal"
            >
              Gateway
            </a>
            <a
              target="_blank"
              href="https://swap.multiswap.network/swap"
              className="text-primaryLight hover:text-secondary px-3 py-2  text-base font-normal"
            >
              MultiSwap
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
