export const StatsCardSkeleton: React.FC = () => (
  <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 font-ModAberMano rounded-2xl p-8 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4 border border-borderBackgroundLight animate-pulse">
    <div className="flex items-center space-x-2">
      <div className="w-8 h-8 bg-background rounded-full"></div>
      <div>
        <div className="h-4 w-20 bg-background rounded mb-2"></div>
        <div className="h-6 w-28 bg-background rounded"></div>
      </div>
    </div>

    <div className="flex items-center space-x-2">
      <div className="w-8 h-8 bg-background rounded-full"></div>
      <div>
        <div className="h-4 w-28 bg-background rounded mb-2"></div>
        <div className="h-6 w-32 bg-background rounded"></div>
      </div>
    </div>

    <div className="flex items-center space-x-2">
      <div className="w-8 h-8 bg-background rounded-full"></div>
      <div>
        <div className="h-4 w-24 bg-background rounded mb-2"></div>
        <div className="h-6 w-28 bg-background rounded"></div>
      </div>
    </div>

    <div className="flex flex-col items-center space-x-2">
      <div className="h-4 w-24 bg-background rounded mb-2"></div>
      <div className="h-24 w-40 bg-background rounded"></div>
    </div>
  </div>
);
