import React from "react";
import { Images } from "../assets";

const Form: React.FC = () => {
  return (
    <div className="bg-backgroundLight p-6  shadow-md font-ModAberMano border border-borderBackgroundLight rounded-[20px] ">
      <ul className="list-decimal list-inside text-xs text-primaryLight mb-4 bg-backgroundDark p-4 border border-borderBackgroundLight rounded-md space-y-3">
        <li>
          If It Compiles Correctly At REMIX, It Should Also Compile Correctly
          Here.
        </li>
        <li>
          As This Is An Beta Release Module, There Is Limited Support For
          External Libraries.
        </li>
        <li>
          There Is A Timeout Of Up To 45 Seconds For Each Contract Compiled. If
          You Need Longer Compilation Times (Up To 3 Mins) Check Out This API
          Endpoint.
        </li>
      </ul>

      <div className="flex items-center  justify-between mt-10  border-b-2 border-borderBackgroundLight pb-5">
        <div className="flex j w-full  gap-5">
          <div className="w-[47%] mb-4">
            <label
              className="block text-primaryLight text-xs mb-2"
              htmlFor="contractAddress"
            >
              Contract Address
            </label>
            <div className=" px-4  flex rounded-md  bg-backgroundDark border border-borderBackgroundLight">
              <img className="w-6 " src={Images.contractInputIcon} alt="" />
              <input
                id="contractAddress"
                type="text"
                className="ml-auto p-2 text-primaryLight focus:outline-none bg-backgroundDark w-full "
                placeholder="0x...."
              />
            </div>
          </div>

          <div className="w-[47%] mb-4">
            <div className="pr-2">
              <label
                className="block text-primaryLight text-xs mb-2"
                htmlFor="compiler"
              >
                Compiler
              </label>
              <div className=" px-4 p-[2px] flex rounded-md  bg-backgroundDark border border-borderBackgroundLight">
                <img className="w-6 " src={Images.compilerInput} alt="" />
                <select
                  id="licenseType"
                  className="w-full p-2 bg-backgroundDark focus:outline-none text-primaryLight"
                >
                  <option>Please Select</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <p className="text-primaryLight mb-2 text-xs">Optimization</p>
          <label className="relative inline-flex items-center cursor-pointer mb-7">
            <input type="checkbox" value="" className="sr-only peer" />
            <div className="w-12 h-5 bg-[#484848]  peer-focus:outline-0 peer-focus:ring-transparent rounded-full peer transition-all ease-in-out duration-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-[22px] after:transition-all peer-checked:bg-primary "></div>
          </label>
        </div>
      </div>

      <div className="pb-3">
        <label className="block text-primaryLight text-xs my-6">
          Please select the Solidity (*.sol) files for upload *
        </label>
        <div className="flex gap-5 justify-between">
          <div className=" px-4 p-[2px] flex rounded-md  bg-background border border-borderBackgroundLight  items-center w-[70%]">
            <img className="w-6 " src={Images.selectFilesIcon} alt="" />
            <input
              type="file"
              className="w-full p-2 bg-background text-primaryLight  text-sm rounded-md border-background"
              multiple
            />
            <span className="text-xs text-primaryLight w-full bf ml-36 bg-backgroundLight p-2 rounded">
              CTRL click to select Multiple files
            </span>
          </div>
          <button className="px-4 p-[2px] flex rounded-md   border border-borderBackgroundLight  items-center text-primaryLight w-[30%] bg-background">
            Click to Upload selected files
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form;
{
  /* <input
type="file"
className="w-full p-2 bg-background text-primaryLight  text-sm rounded-md border-gray-600"
multiple
/> */
}
