import React from "react";

export const LatestBlockOrTransactionSkeleton: React.FC = () => {
  return (
    <div className="animate-pulse space-y-4">
      {/* Loader for each item */}
      {[...Array(5)].map((_, i) => (
        <div
          key={i}
          className="flex items-center justify-between bg-blend-darken p-4 rounded-lg mb-3"
        >
          {/* Left side (icon and details) */}
          <div className="flex items-center space-x-4">
            {/* Circular icon loader */}
            <div className="w-10 h-10 bg-backgroundDark rounded-full"></div>
            {/* Text lines */}
            <div className="space-y-2">
              <div className="w-24 h-4 bg-backgroundDark rounded"></div>
              <div className="w-16 h-3 bg-backgroundDark rounded"></div>
            </div>
          </div>
          {/* Right side (button or label loader) */}
          <div className="w-20 h-6 bg-backgroundDark rounded"></div>
        </div>
      ))}
    </div>
  );
};
