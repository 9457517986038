import React from "react";
import MainLayout from "../layout/MainLayout";
import SearchBar from "../components/SearchBar";
import { validatorData } from "../utils/helper";
import ValidatorTable from "../components/ValidatorTable";
import ContractList from "../components/ContractList";
import AdvancedFilter from "../components/AdvancedFilter";
import ContractAddressHeader from "../components/ContractAddressHeader";
import { Images } from "../assets";

const SmartContractSearch: React.FC = () => {
  return (
    <MainLayout>
      <SearchBar />
      <div className="max-w-6xl mx-auto ">
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between font-ModAberMano">
            <div className=" flex items-center gap-2">
              <img src={Images.qpLogoIcon} alt="" />
              <span className="font-medium text-lg text-primaryLight">
                Smart Contract Search
              </span>
            </div>
            <div className=" flex items-center gap-2">
              <img src={Images.contractSearchIcon} alt="" />
              <span className="font-medium text-sm text-primaryLight">
                Contract:{" "}
                <span>0xdac17f958d2ee523a2206206994597c13d831ec7</span>
              </span>
            </div>
          </div>
          <p className="text-primaryLight text-base font-medium font-ModAberMano ml-16">
            A total of 3,412 records found
          </p>
        </div>

        <div className="flex gap-7 my-10">
          <div className="w-[70%] ">
            <ContractList />
          </div>
          <div className="w-[30%] ">
            <AdvancedFilter />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default SmartContractSearch;
