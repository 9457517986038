import React, { useState } from "react";
import RegisteredRemoteTransaction from "./RegisteredRemoteTransaction";
import TargetBlockMinedCard from "./TargetBlockMined";
import TransactionLogs from "./TransactionLogs";
const Index: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Registered remote transaction");

  const tabs = [
    { name: "Registered remote transaction" },
    { name: "Target Block - Mined" },
    { name: "Transaction Logs" },
  ];

  const remoteTransactionData = {
    timestamp: "2024-08-14 12:34:56",
    remoteContract: "0xABCDEF1234567890ABCDEF1234567890ABCDEF12",
    sourceMsgSender: "0x1234567890ABCDEF1234567890ABCDEF12345678",
    sourceBeneficiary: "0x0987654321FEDCBA0987654321FEDCBA09876543",
    token: "0x7F1Dc79E1fcB7592D705BA2b6B2074cB73F21915",
    amount: 1.2345,
    methods: "transfer",
    gasFee: "0.0023 MATIC",
    fixedFee: "2234324",
  };
  const targetBlockMinedData = {
    blockHash: "0x7F1Dc79E1fcB7592D705BA2b6B2074cB73F21915",
    miner: "0x3A9e7492D876f9A3BF354b9AE525EeF86315bd38",
    invalidBlock: false,
    state: 13312311,
    totalValue: "1230000 USDC",
    sourceChainId: "MATIC",
    nonce: 4,
    timestamp: "0.0184 MATIC",
    fixedFee: "1 Day 17 Hrs Ago (Jan-24-2023 01:46:59 AM +UTC)",
  };

  return (
    <>
      {/* header  */}
      <div className="flex justify-between">
        <div className=" flex items-center gap-24 font-ModAberMano mt-10 mb-6  px-6">
          {tabs.map((tab) => (
            <span
              key={tab.name}
              className={`text-lg font-semibold text-primaryLight cursor-pointer ${
                activeTab === tab.name
                  ? "border-b-2 border-primary pb-1 "
                  : "bg-backgroundDark text-primaryLight"
              }`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.name}
            </span>
          ))}
        </div>
      </div>
      {activeTab === "Registered remote transaction" && (
        <RegisteredRemoteTransaction data={{ ...remoteTransactionData }} />
      )}
      {activeTab === "Target Block - Mined" && (
        <TargetBlockMinedCard data={{ ...targetBlockMinedData }} />
      )}
      {activeTab === "Transaction Logs" && <TransactionLogs />}
    </>
  );
};

export default Index;
