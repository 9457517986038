import React, { useEffect, useState } from "react";
import MainLayout from "../layout/MainLayout";
import BlocksTable from "../components/BlocksTable";
import Pagination from "../components/Pagination";
import { useBlocks } from "../hooks/index";
import SearchBar from "../components/SearchBar";
import { useLocation } from "react-router-dom";

const Blocks: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState<any[]>([]); // State to store filtered data
  const location = useLocation();

  const {
    data: blocks,
    error: blockError,
    isLoading: blocksLoading,
  } = useBlocks(currentPage, recordsPerPage);

  useEffect(() => {
    if (location.state?.filteredData) {
      setFilteredData(location.state.filteredData);
      setCurrentPage(1); // Reset to first page when new filtered data is applied
    }
  }, [location.state]);

  const handlePageChange = (newPage: number) => setCurrentPage(newPage);

  const handleRecordsPerPageChange = (newRecordsPerPage: number) => {
    setRecordsPerPage(newRecordsPerPage);
    setCurrentPage(1); // Reset to the first page when records per page changes
  };

  // Get paginated data for filtered results
  const paginatedFilteredData = filteredData.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  return (
    <MainLayout>
      <SearchBar /> {/* Pass the function */}
      <div className="max-w-6xl mx-auto my-16">
        {blocksLoading && !filteredData.length ? ( // Display loading spinner only when no filtered data
          <BlocksTable blockList={[]} isLoading={true} />
        ) : blockError ? (
          <div className="text-red-500">
            An error occurred while fetching data.
          </div>
        ) : filteredData.length > 0 ? ( // Display filtered data if available
          <>
            <BlocksTable blockList={paginatedFilteredData} isLoading={false} />
            <Pagination
              currentPage={currentPage}
              recordsPerPage={recordsPerPage}
              totalPages={Math.ceil(filteredData.length / recordsPerPage)}
              totalResults={filteredData.length}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          </>
        ) : (
          <>
            <BlocksTable blockList={blocks.results} />
            <Pagination
              currentPage={currentPage}
              recordsPerPage={recordsPerPage}
              totalPages={blocks?.totalPages}
              totalResults={blocks?.totalResults}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Blocks;
