import React from "react";
import { Images } from "../../assets";

interface CardData {
  title: string;
}

interface CardProps {
  data: CardData;
}

const SourceNetworkCard: React.FC<CardProps> = ({ data }) => {
  return (
    <div className="bg-backgroundLight max-w-[550px] p-4 border border-borderBackgroundLight rounded-[20px] space-y-4">
      <div className=" border-b border-borderBackgroundLight pb-2 text-primaryLight">
        {data.title}
      </div>
      <select
        id="countries"
        className="bg-background  border border-borderBackgroundLight text-primaryLight text-sm rounded-lg w-full px-2 py-2"
      >
        <option selected>bitcoin</option>
        <option value="US">etherium</option>
      </select>
      <input
        type="text"
        placeholder=" Transaction ID"
        className="font-normal text-xs py-2 rounded-md placeholder:text-primaryLight placeholder:font-ModAberMano px-2 border border-borderBackgroundLight w-full bg-background text-primaryLight"
      />
      <button className=" text-xs py-2 rounded-md placeholder:text-primaryLight placeholder:font-ModAberMano px-2 border border-borderBackgroundLight w-full bg-primary  text-primaryContrast font-bold font-ModAberMano">
        Load Transaction
      </button>
    </div>
  );
};

export default SourceNetworkCard;
