import React, { useState } from "react";
import { Images, ReactIcons } from "../assets";
import { Link } from "react-router-dom";
import { Transaction } from "../interfaces";
import { formatTimeAgoWithTimestamp } from "../utils/helper";
import { CHAINS_EXPLORER } from "../utils/contant";

interface TransactionDetailsCardProps {
  tx: Transaction;
  handleTabSwitch: (tab: "overview" | "logs") => void;
  activeTab: "overview" | "logs";
}

const TransactionDetailsCard: React.FC<TransactionDetailsCardProps> = ({
  tx,
  activeTab,
}) => {
  const [copiedFields, setCopiedFields] = useState<Record<string, boolean>>({});
  const [decodeInput, setDecodeInput] = useState<boolean>(false);
  const [encoding, setEncoding] = useState("Hex (Default)");
  const [displayData, setDisplayData] = useState(tx.inputData || "0x");

  const handleEncodingChange = (event: { target: { value: any } }) => {
    const selectedEncoding = event.target.value;
    setEncoding(selectedEncoding);
    if (selectedEncoding === "Decode") {
      setDecodeInput(true);
    } else if (selectedEncoding === "UTF-8") {
      try {
        const utf8Data = hexToUtf8(tx.inputData || "0x");
        setDisplayData(utf8Data);
        setDecodeInput(false);
      } catch (error) {
        setDisplayData("Invalid UTF-8 data");
        setDecodeInput(false);
      }
    } else {
      setDisplayData(tx.inputData || "0x"); // Default Hex
      setDecodeInput(false);
    }
  };

  // Function to convert Hex to UTF-8 using modern methods
  const hexToUtf8 = (hex: string) => {
    if (!hex || hex === "0x") return "0x";
    try {
      // Remove the '0x' prefix if present
      const cleanHex = hex.startsWith("0x") ? hex.slice(2) : hex;

      // Split hex into pairs and convert to bytes
      const bytes =
        cleanHex
          ?.match(/.{1,2}/g) // Returns an array or null
          ?.map((byte) => parseInt(byte, 16)) || []; // Handle null by using an empty array

      // Decode the array of bytes to a UTF-8 string
      const decoder = new TextDecoder("utf-8");
      return decoder.decode(new Uint8Array(bytes));
    } catch (error) {
      return "Invalid UTF-8 data";
    }
  };

  const handleCopyClick = (field: any, text: any) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedFields((prev) => ({
          ...prev,
          [field]: true,
        }));
        setTimeout(
          () =>
            setCopiedFields((prev) => ({
              ...prev,
              [field]: false,
            })),
          2000
        ); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const remoteChainIdParam = tx.decodedInput.parameters.find(
    (item) => item.name === "remoteChainId"
  );
  const remoteChainIdValue = remoteChainIdParam?.value;

  const explorerLink =
    typeof remoteChainIdValue === "string" &&
    remoteChainIdValue in CHAINS_EXPLORER
      ? CHAINS_EXPLORER[remoteChainIdValue as keyof typeof CHAINS_EXPLORER]
      : undefined;

  return (
    <>
      <div className="p-6 bg-backgroundLight rounded-[20px] text-primaryLight font-ModAberMano max-w-[1150px] border border-borderBackgroundLight space-y-5">
        {activeTab === "overview" ? (
          <>
            <div className="flex items-center">
              <div className="flex items-center gap-2 text-sm w-[25%] ">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                TRANSACTION HASH:
              </div>
              <div className="text-lg  flex items-center gap-3">
                {/* <img className="h-4 w-4 " src={Images.polygonIcon} /> */}
                {tx?.hash}
                {copiedFields.hash ? (
                  <ReactIcons.IoCheckmark className="w-5 h-5 cursor-pointer" />
                ) : (
                  <ReactIcons.IoCopyOutline
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => handleCopyClick("hash", tx?.hash)}
                  />
                )}

                {/* <img className=" cursor-pointer" src={Images.copyIcon} alt="" /> */}
              </div>
            </div>
            {/* deprected */}
            <div className="hidden items-center">
              <div className="flex items-center gap-2  text-sm w-[25%]">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                STATUS:
              </div>
              <div className="flex items-center">
                {/* <div className="inline-flex items-center justify-center px-2 py-1 text-xs font-semibold text-successColorContrast bg-successColor rounded">
                  <span className="text-green-500">
                    <ReactIcons.FaRegCheckCircle className="text-black mr-[2px]" />
                  </span>
                 
                </div> */}
                {"--"}
              </div>
            </div>

            <div className="flex items-center">
              <div className="flex items-center gap-2  text-sm w-[25%]">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                BLOCK NUMBER:
              </div>
              <div className="flex items-center gap-6">
                <Link to={`/block/${tx?.blockNumber}`}>
                  <span>{tx?.blockNumber}</span>
                </Link>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center self-start gap-2  text-sm w-[25%]">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                TIMESTAMP:
              </div>
              <div className="flex flex-col ">
                <div className="flex items-center gap-2">
                  {formatTimeAgoWithTimestamp(tx.timestamp)}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center gap-2 text-sm w-[25%] ">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                <span>SOURCE MSG SENDER:</span>
              </div>
              <div className="text-lg  flex items-center gap-3">
                {/* <img className="h-4 w-4 " src={Images.polygonIcon} alt="" /> */}
                <a
                  target="_blank"
                  href={`${explorerLink}address/${tx?.sourceMsgSender}`}
                  rel="noopener noreferrer"
                  className="flex items-center gap-2"
                >
                  <span>{tx?.sourceMsgSender}</span>
                </a>
                {copiedFields.sourceMsgSender ? (
                  <ReactIcons.IoCheckmark className="w-5 h-5 cursor-pointer" />
                ) : (
                  <ReactIcons.IoCopyOutline
                    className="w-5 h-5 cursor-pointer"
                    onClick={() =>
                      handleCopyClick("sourceMsgSender", tx?.sourceMsgSender)
                    }
                  />
                )}
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center gap-2 text-sm w-[25%] ">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                <span> COMPOSER: </span>
              </div>
              <div className="text-lg flex items-center gap-3">
                <Link to={`/address/${tx?.remoteContract}`}>
                  <span>{tx?.remoteContract}</span>
                </Link>
                {copiedFields.remoteContract ? (
                  <ReactIcons.IoCheckmark className="w-5 h-5 cursor-pointer" />
                ) : (
                  <ReactIcons.IoCopyOutline
                    className="w-5 h-5 cursor-pointer"
                    onClick={() =>
                      handleCopyClick("remoteContract", tx?.remoteContract)
                    }
                  />
                )}
              </div>
            </div>
            <div className="hidden items-center">
              <div className="flex items-center gap-2  text-sm w-[25%]">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                INTERACTED WITH:
              </div>
              <div>
                {/* <Link to={`/address/${tx.to}`}> */}
                <span>{"--"}</span>
                {/* </Link> */}
              </div>
            </div>
            {/* deprected */}
            <div className="hidden items-center">
              <div className="flex items-center gap-2  text-sm w-[25%]">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                Quantum Portal Contract:
              </div>
              <div>
                <span className="flex items-center gap-2">
                  {/* <Link to={`/contract/${tx.to}`}> */}
                  <span>{"--"}</span>
                  {/* </Link> */}
                  {/* {copied ? (
                  <ReactIcons.IoCheckmark
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => handleCopyClick(tx?.hash)}
                  />
                ) : (
                  <ReactIcons.IoCopyOutline
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => handleCopyClick(tx?.hash)}
                  />
                )} */}
                  {/* <img className="h-4 w-4 " src={Images.copyIcon} alt="" /> */}
                </span>
              </div>
            </div>
            {/* deprected */}
            <div className="hidden items-center">
              <div className="flex items-center gap-2  text-sm w-[25%]">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                Source Token:
              </div>
              <div className="flex items-center gap-2">
                {/* <img className="h-4 w-4 " src={Images.bscIcon} alt="" /> */}
                <span>{"--"}</span>
                {/* <img
                  className="cursor-pointer"
                  src={Images.explorerIcon}
                  alt=""
                /> */}
              </div>
            </div>
            {/* deprected */}
            <div className="hidden items-center">
              <div className="flex items-center gap-2  text-sm w-[25%]">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                Destination Token:
              </div>
              <div className="flex items-center gap-2">
                {/* <img className="h-4 w-4 " src={Images.bscIcon} alt="" /> */}
                <span>{"--"}</span>
                {/* <img
                  className="cursor-pointer h-3.5 w-3.5"
                  src={Images.explorerIcon}
                  alt=""
                /> */}
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center gap-2  text-sm w-[25%]">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                TRANSACTION FEE:
              </div>
              <div className="flex items-center gap-2">
                {/* <img className="h-4 w-4 " src={Images.frmLogo} alt="" /> */}
                <span>{tx.fee}</span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center gap-2  text-sm w-[25%]">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                GAS FEE:
              </div>
              <div className="flex items-center gap-2">
                {/* <img className="h-4 w-4 " src={Images.polygonIcon} alt="" /> */}
                <span>{tx?.gasPrice}</span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center gap-2  text-sm w-[25%]">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                NONCE:
                <span className=" p-1.5 bg-backgroundDark rounded-md">
                  Position
                </span>
              </div>
              <div className="flex items-center gap-2">
                {/* <img className="h-4 w-4 " src={Images.polygonIcon} alt="" /> */}
                <span>{tx?.nonce}</span>
                <span className="p-1 bg-backgroundDark">{tx?.position}</span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center gap-2  text-sm w-[25%] self-start">
                <img
                  className="h-4 w-4 "
                  src={Images.questionMarkIcon}
                  alt=""
                />
                DATA:
                <select
                  id=""
                  className="bg-[#262626] border border-borderBackgroundLight text-gray-900 text-xs rounded-lg max-w-36   px-3 py-1 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 "
                  onChange={handleEncodingChange}
                >
                  <option selected>Hex (Default)</option>
                  <option>UTF-8</option>
                  <option>Decode</option>
                </select>
              </div>
              <div className="w-[75%] space-y-4 flex flex-col gap-3">
                <div className="flex p-2 bg-backgroundDark rounded-md w-full justify-between min-h-20 relative  ">
                  {!decodeInput ? (
                    <span className="text-sm break-all max-w-[90%]">
                      {displayData}
                    </span>
                  ) : (
                    <div className="p-4  rounded-md bg-background">
                      <div className="mb-4">
                        <strong>Function:</strong> {tx.decodedInput.method_call}
                      </div>
                      <div className="overflow-auto  rounded-md bg-background">
                        <table className="table-auto border-none w-full text-left">
                          <thead className="bg-background ">
                            <tr className="border-b">
                              <th className="p-2">#</th>
                              <th className="p-2">Name</th>
                              <th className="p-2">Type</th>
                              <th className="p-2">Data</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tx.decodedInput.parameters.map((param, index) => (
                              <tr key={index} className="">
                                <td className="p-2 border-b">{index + 1}</td>
                                <td className="p-2 border-b">{param.name}</td>
                                <td className="p-2 border-b">{param.type}</td>
                                <td className="p-2 border-b break-all">
                                  {param.value.toString()}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {/* {copiedFields.data ? (
                    <ReactIcons.IoCheckmark className="w-5 h-5 cursor-pointer" />
                  ) : (
                    <ReactIcons.IoCopyOutline
                      className="w-5 h-5 cursor-pointer"
                      onClick={() =>
                        handleCopyClick(
                          "data",
                          tx.inputData ? tx.inputData : ""
                        )
                      }
                    />
                  )} */}
                </div>
                {/* <ul className="px-4">
                  <span
                    className="p-2 bg-primary font-semibold text-primaryContrast rounded-md cursor-pointer"
                    onClick={() => setDecodeInput(!decodeInput)}
                  >
                    {decodeInput ? "Switch back" : "Decode Input Data"}
                  </span>
                </ul> */}
              </div>
            </div>
          </>
        ) : (
          <>
            {tx?.logs && tx.logs.length > 0 ? (
              tx.logs.map((log, index) => (
                <div
                  key={index}
                  className="space-y-4 border-b border-borderBackgroundLight py-6"
                >
                  <div className="flex items-center">
                    <div className="flex items-center gap-2 text-sm w-[28%]">
                      <img
                        className="h-4 w-4"
                        src={Images.questionMarkIcon}
                        alt="Icon"
                      />
                      ADDRESS:
                    </div>
                    <span>{log.address.hash}</span>
                  </div>
                  <div className="flex items-center">
                    <div className="flex items-center gap-2 text-sm w-[28%]">
                      <img
                        className="h-4 w-4"
                        src={Images.questionMarkIcon}
                        alt="Icon"
                      />
                      NAME:
                    </div>
                    <p className="w-[72%]">{log.decoded.method_call}</p>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center gap-2 text-sm w-[28%]">
                      <img
                        className="h-4 w-4"
                        src={Images.questionMarkIcon}
                        alt="Icon"
                      />
                      TOPICS:
                    </div>
                    <div className="overflow-auto rounded-md bg-background w-[75%]">
                      <table className="table-auto border-none w-full text-left">
                        <thead className="bg-background">
                          <tr className="border-b">
                            <th className="p-2">#</th>
                            <th className="p-2">Name</th>
                            <th className="p-2">Type</th>
                            <th className="p-2">Data</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(log.decoded.parameters) &&
                            log.decoded.parameters.map((param, index) => (
                              <tr key={index}>
                                <td className="p-2 border-b">{index + 1}</td>
                                <td className="p-2 border-b">{param.name}</td>
                                <td className="p-2 border-b">{param.type}</td>
                                <td className="p-2 border-b break-all">
                                  {param.value.toString()}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex items-center gap-2 text-sm w-[28%] self-start">
                      <img
                        className="h-4 w-4"
                        src={Images.questionMarkIcon}
                        alt="Icon"
                      />
                      DATA:
                    </div>
                    <div className="flex p-2 bg-backgroundDark rounded-md w-[75%] justify-between min-h-20 relative">
                      <span className="text-sm break-all max-w-[80%]">
                        {log?.data}
                      </span>
                      {/* Uncomment and use if needed */}
                      {/* {copiedFields.data ? (
              <ReactIcons.IoCheckmark className="w-5 h-5 cursor-pointer" />
            ) : (
              <ReactIcons.IoCopyOutline
                className="w-5 h-5 cursor-pointer"
                onClick={() => handleCopyClick(log.address.name, log?.data)}
              />
            )} */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Logs Are Found</p>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TransactionDetailsCard;
