import React, { useEffect, useState } from "react";
import MainLayout from "../layout/MainLayout";
import SearchBar from "../components/SearchBar";
import TransactionsTable from "../components/TransactionsTable";
import Pagination from "../components/Pagination";
import { useTransactions } from "../hooks/index";
import { useLocation } from "react-router-dom";

const Transactions: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const location = useLocation();

  const {
    data: transactions,
    error: transactionsError,
    isLoading: transactionsLoading,
  } = useTransactions(currentPage, recordsPerPage);

  // This useEffect will always be called in every render
  useEffect(() => {
    if (location.state?.filteredData) {
      setFilteredData(location.state.filteredData);
      setCurrentPage(1); // Reset to first page when new filtered data is applied
    }
  }, [location.state]);

  const handlePageChange = (newPage: number) => setCurrentPage(newPage);

  const handleRecordsPerPageChange = (newRecordsPerPage: number) => {
    setRecordsPerPage(newRecordsPerPage);
    setCurrentPage(1); // Reset to the first page when records per page changes
  };

  const paginatedFilteredData = filteredData.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  return (
    <MainLayout>
      <SearchBar />
      <div className="max-w-6xl mx-auto my-16">
        {transactionsLoading && !filteredData.length ? (
          <TransactionsTable transactionList={[]} isLoading={true} />
        ) : transactionsError ? (
          <div className="text-red-500">
            An error occurred while fetching data.
          </div>
        ) : filteredData.length > 0 ? (
          <>
            <TransactionsTable
              transactionList={paginatedFilteredData}
              isLoading={false}
              headerVisible={false}
            />
            <Pagination
              currentPage={currentPage}
              recordsPerPage={recordsPerPage}
              totalPages={Math.ceil(filteredData.length / recordsPerPage)}
              totalResults={filteredData.length}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          </>
        ) : (
          <>
            <TransactionsTable
              transactionList={transactions.results}
              isLoading={false}
            />
            <Pagination
              currentPage={currentPage}
              recordsPerPage={recordsPerPage}
              totalPages={transactions?.totalPages}
              totalResults={transactions?.totalResults}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Transactions;
