import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchAccountInfo = async (address: string) => {
  const url = `${
    process.env.REACT_APP_SERVER_URL
  }/accounts/addresses/${address.toLowerCase()}`;

  const { data } = await axios.get(url);

  return data;
};

export const useAccountInfo = (address: string) => {
  return useQuery({
    queryKey: ["accounts", address],
    queryFn: () => fetchAccountInfo(address),
    enabled: !!address,
  });
};
