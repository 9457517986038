import React, { useState } from "react";
import MainLayout from "../layout/MainLayout";
import FinalizedBlockCard from "../components/FinalizedBlockCard";
import { useParams } from "react-router-dom";
import { useBlockByIdOrHash } from "../hooks";
import SearchBar from "../components/SearchBar";
import { ReactIcons } from "../assets";
import { BlockSkeleton } from "../components/skeleton/BlockSkeleton";
// import useBlocks from "../hooks/useBlocks";

const BlocksDetails: React.FC = () => {
  const { id: blockId } = useParams<{ id: string }>();

  const {
    data: singleBlock,
    isLoading,
    error,
  } = useBlockByIdOrHash(blockId || "");

  if (!blockId) {
    return <div>Error: Block ID is missing.</div>;
  }

  if (error) {
    return <div>Error fetching transaction details.</div>;
  }
  return (
    <MainLayout>
      <SearchBar />
      <div className="max-w-6xl mx-auto my-16">
        <div className="flex items-center gap-4  text-primaryLight font-ModAberMano my-7">
          {/* <img src={Images.bscIcon} alt="bsc logo" />
          <img src={Images.algorendIcon} alt="algorend logo" /> */}
          <span className="text-lg">Block # {singleBlock?.number}</span>
          <div className="hidden gap-3">
            <span>
              <ReactIcons.FaLessThan className="w-4 h-4 rounded-md bg-background cursor-pointer" />
            </span>
            <span>
              <ReactIcons.FaGreaterThan className=" w-4 h-4 rounded-md bg-background cursor-pointer" />
            </span>
          </div>
          {/* deprected */}
          <span
            className={`hidden px-2 py-[2px] bg-primary  items-center text-xs rounded-full text-primaryContrast uppercase font-medium `}
          >
            {"finalized"}
          </span>
        </div>
        {isLoading ? (
          <BlockSkeleton />
        ) : (
          <FinalizedBlockCard block={singleBlock} isLoading={isLoading} />
        )}
      </div>
    </MainLayout>
  );
};

export default BlocksDetails;
