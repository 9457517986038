import { Images } from "../assets";
// Define the block explorer URLs for different chain IDs
export const CHAINS_EXPLORER = {
  "42161": "https://arbiscan.io/",
  "56": "https://bscscan.com/",
  "8453": "https://basescan.org/",
};
export const CHAIN_LOGOS = {
  "42161": "https://cryptologos.cc/logos/arbitrum-arb-logo.png", // Arbitrum logo
  "56": "https://cryptologos.cc/logos/binance-coin-bnb-logo.png", // Binance Smart Chain logo
  "8453": Images.baseLogoIcon, // Base logo
};
export const walletImages = {
  walletConnect:
    "https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png",
};

export const FERRUM_CHAIN_ID = 26100;
