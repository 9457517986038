// React Icons
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import { CiCirclePlus } from "react-icons/ci";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoCheckmark } from "react-icons/io5";
import { IoCopyOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

import qpLogo from "./images/qpLogo.svg";
import footerLogo from "./images/footerLogo.svg";
import searchFilter from "./images/searchFilter.svg";
import frmLogo from "./images/frmLogo.svg";
import marketCap from "./images/marketCap.svg";
import transactionsIcon from "./images/transactionsIcon.svg";
import volumeImage from "./images/volumeImage.svg";
import bscIcon from "./images/bscIcon.svg";
import algorendIcon from "./images/algorendIcon.svg";
import ethIcon from "./images/ethIcon.svg";
import explorerIcon from "./images/explorerIcon.svg";
import polygonIcon from "./images/polygonIcon.svg";
import plusIcon from "./images/plusIcon.svg";
import qpLogoIcon from "./images/qpLogoIcon.svg";
import filterIcon from "./images/filterIcon.svg";
import copyIcon from "./images/copyIcon.svg";
import qrIcon from "./images/qrIcon.svg";
import questionMarkIcon from "./images/questionMarkIcon.svg";
import leftArrow from "./images/leftArrow.svg";
import rightArrow from "./images/rightArrow.svg";
import filterHeaderIcon from "./images/filterHeaderIcon.svg";
import successIcon from "./images/successIcon.svg";
import ideIcon from "./images/ideIcon.svg";
import copyCodeIcon from "./images/copyCodeIcon.svg";
import codeLInkIcon from "./images/codeLInkIcon.svg";
import settingCodeIcon from "./images/settingCodeIcon.svg";
import inputSearchIcon from "./images/inputSearchIcon.svg";
import arrowUp from "./images/arrowUp.svg";
import arrowDown from "./images/arrowDown.svg";
import accordianLInkIcon from "./images/accordianLInkIcon.svg";
import bineryIcon from "./images/bineryIcon.svg";
import contractInputIcon from "./images/contractInputIcon.svg";
import compilerInput from "./images/compilerInput.svg";
import licenceInput from "./images/licenceInput.svg";
import contractNameIcon from "./images/contractNameIcon.svg";
import contractSearchIcon from "./images/contractSearchIcon.svg";
import alertIcon from "./images/alertIcon.svg";
import alertCancel from "./images/alertCancel.svg";
import selectFilesIcon from "./images/selectFilesIcon.svg";
import alertInfoIcon from "./images/alertInfoIcon.svg";
import recaptcha from "./images/recaptcha.svg";
import signoutIcon from "./images/signoutIcon.svg";
import pagenotfoundbg from "./images/pagenotfoundbg.svg";
import pagenotfound from "./images/pagenotfound.svg";
import qplarglogo from "./images/qplarglogo.svg";
import frmFullLogo from "./images/frmFullLogo.svg";
import frmLogoColorIcon from "./images/frmLogoColorIcon.svg";
import worldIcon from "./images/worldIcon.svg";
import dateIcon from "./images/dateIcon.svg";
import tableFilter from "./images/tableFilter.svg";
import copyCheckMark from "./images/copyCheckMark.png";
import baseLogoIcon from "./images/baseLogoIcon.jpeg";

export const ReactIcons = {
  AiOutlineMenu,
  AiOutlineClose,
  CiSearch,
  CiCirclePlus,
  FaLessThan,
  FaGreaterThan,
  MdOutlineWatchLater,
  FaRegCheckCircle,
  MdOutlineKeyboardArrowDown,
  IoCheckmark,
  IoCopyOutline,
  IoMdClose,
};

export const Images = {
  qpLogo,
  footerLogo,
  searchFilter,
  frmLogo,
  marketCap,
  transactionsIcon,
  volumeImage,
  bscIcon,
  algorendIcon,
  ethIcon,
  explorerIcon,
  polygonIcon,
  plusIcon,
  qpLogoIcon,
  filterIcon,
  copyIcon,
  qrIcon,
  questionMarkIcon,
  leftArrow,
  rightArrow,
  filterHeaderIcon,
  successIcon,
  ideIcon,
  copyCodeIcon,
  codeLInkIcon,
  settingCodeIcon,
  inputSearchIcon,
  accordianLInkIcon,
  arrowDown,
  arrowUp,
  bineryIcon,
  contractInputIcon,
  compilerInput,
  licenceInput,
  contractNameIcon,
  contractSearchIcon,
  alertIcon,
  alertCancel,
  selectFilesIcon,
  alertInfoIcon,
  recaptcha,
  signoutIcon,
  pagenotfoundbg,
  qplarglogo,
  pagenotfound,
  frmFullLogo,
  frmLogoColorIcon,
  worldIcon,
  dateIcon,
  tableFilter,
  copyCheckMark,
  baseLogoIcon,
};
