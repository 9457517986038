import React, { useState } from "react";
import MainLayout from "../../layout/MainLayout";
import ConnectedAccount from "./ConnectedAccount";
import MinerDashboard from "./MinerDashboard";
import StakeActions from "./StakeActions";
import { Button } from "../../components/ui/Button";
import WithdrawCard from "./WithdrawCard";

const MinerStakePage: React.FC = () => {
  // State to track the active section
  const [activeSection, setActiveSection] = useState("MINER2");

  return (
    <MainLayout>
      <div className="max-w-[1200px] mx-auto my-16 font-ModAberMano">
        <header className="flex flex-col justify-center items-center pb-20 ">
          <h1 className="text-xl font-semibold text-primaryLight tracking-widest mb-4">
            QUANTUM PORTAL EXPLORER MINER STAKE
          </h1>
          <div className=" justify-center items-center gap-8 mt-4">
            <p className="hidden text-primaryLight ">
              0x4bba9b6bdfa66107...aa68b04a4d
            </p>
            <Button
              title="Connect"
              variant="primary"
              className="text-sm py-2 px-6 uppercase"
            />
          </div>
          <p className=" text-primaryLight font-semibold mt-4">
            Please connect your wallet and select your role to continue
          </p>
          {/* top menu */}
          <ul className="flex items-center justify-between bg-backgroundLight rounded-[100px] p-3 gap-3 mt-24">
            <Button
              title="STAKER"
              rounded="full"
              variant={activeSection === "STAKER" ? "primary" : "secondary"}
              onClick={() => setActiveSection("STAKER")}
            />
            <Button
              title="MINER"
              rounded="full"
              variant={activeSection === "MINER" ? "primary" : "secondary"}
              onClick={() => setActiveSection("MINER")}
            />
            <Button
              title="OPERATOR"
              rounded="full"
              variant={activeSection === "OPERATOR" ? "primary" : "secondary"}
              onClick={() => setActiveSection("OPERATOR")}
            />
          </ul>

          <button className="hidden bg-primary text-sm text-primaryContrast py-2 px-6 rounded-md font-semibold transition duration-200">
            CONNECT A WALLET
          </button>
        </header>

        {/* Content based on active section */}
        {activeSection === "STAKER" && (
          <div className="bg-background px-6 pb-8 rounded-[20px]">
            <p className="py-4 text-primary uppercase text-lg">
              Welcome STAKER
            </p>
            <div className="flex gap-10">
              <div className="flex flex-col gap-6 w-full">
                <MinerDashboard />
                <WithdrawCard />
              </div>
              <div className="flex flex-col gap-6 w-full ">
                <ConnectedAccount title="staker" />
                <StakeActions />
              </div>
            </div>
          </div>
        )}

        {activeSection === "MINER" && (
          <div className="bg-background px-6 pb-8 rounded-[20px]">
            <p className="py-4 text-primary uppercase text-lg">Welcome MINER</p>
            <div className="flex gap-10">
              <div className="flex flex-col gap-6 w-full">
                <MinerDashboard />
              </div>
              <div className="flex flex-col gap-6 w-full">
                <ConnectedAccount title="miner" />
              </div>
            </div>
          </div>
        )}

        {activeSection === "OPERATOR" && (
          <div className="bg-background px-6 pb-8 rounded-[20px]">
            <p className="py-4 text-primary uppercase text-lg">
              Welcome OPERATOR
            </p>
            <div className="flex gap-10">
              <div className="flex flex-col gap-6 w-full">
                <MinerDashboard />
              </div>
              <div className="flex flex-col gap-6 w-full ">
                <ConnectedAccount title="VALIDATOR" />
              </div>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default MinerStakePage;
