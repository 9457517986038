import React from "react";
import MainLayout from "../layout/MainLayout";
import SearchBar from "../components/SearchBar";
import Form from "../components/VerifyAndPublishForm";
import Accordion from "../components/Accordion";
import ActionButtons from "../components/ActionButton";
import { Images } from "../assets";

const VerifyAndPublish: React.FC = () => {
  return (
    <MainLayout>
      <SearchBar />
      <div className="max-w-6xl mx-auto my-16">
        <div className="flex items-center justify-between text-xs py-3 px-6 rounded-md mb-2 border border-borderBackgroundLight">
          <div className="flex items-center gap-6">
            <img src={Images.alertInfoIcon} alt="" />
            <span className="text-borderBackgroundLight">
              This Is An Experimental Source Code Verifier Which Supports
              Verification Of Multi-Part Solidity Files (Imports).
            </span>
          </div>
          <img src={Images.alertCancel} alt="" />
        </div>
        <div className="flex items-center justify-between font-ModAberMano my-10">
          <div className="flex items-center gap-5">
            <img src={Images.qpLogoIcon} alt="" />
            <span className="text-lg text-primaryLight font-medium">
              Verify & Publish Contract Source Code
            </span>
          </div>
          <span className="bg-successColor text-successColorContrast text-[10px] rounded-2xl px-4 py-1">
            Compiler Type: SOLIDITY MULTI-PART VERIFIER (IMPORTS)
          </span>
        </div>
        <Form />
        <Accordion title="Constructor Arguments ABI-Encoded (For Contracts That Were Created With Constructor Parameters)">
          <p className="text-sm text-primaryLight">
            Constructor Arguments ABI-encoded (for contracts that were created
            with constructor parameters)
          </p>
        </Accordion>
        <Accordion title="Contract Library Address (For Contracts That Use Libraries. Supports Up To 10 Libraries)">
          <p className="text-sm text-primaryLight">
            Contract Library Address (for contracts that use libraries, supports
            up to 10 libraries)
          </p>
        </Accordion>
        <Accordion title="Misc Settings (Runs, EvmVersion & License Type Settings)">
          <p className="text-sm text-primaryLight">
            Misc Settings (Runs, EvmVersion & License Type settings)
          </p>
        </Accordion>
        <ActionButtons />
      </div>
    </MainLayout>
  );
};

export default VerifyAndPublish;
