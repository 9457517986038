import React, { useState } from "react";
import { Images } from "../assets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDropdown from "./CustomDropdown";

interface FilterDialogProps {
  selectedType: string;
  setSelectedType: (value: string) => void;
  selectedTo: string;
  setSelectedTo: (value: string) => void;
  selectedFrom: string;
  setSelectedFrom: (value: string) => void;
  startDate: Date | undefined;
  setStartDate: (value: Date | undefined) => void;
  endDate: Date | undefined;
  setEndDate: (value: Date | undefined) => void;
  isOpen: boolean;
  onClose: () => void;
  onApply: () => void;
  onReset: () => void;
}

const FilterDialog: React.FC<FilterDialogProps> = ({
  selectedType,
  setSelectedType,
  selectedTo,
  setSelectedTo,
  selectedFrom,
  setSelectedFrom,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isOpen,
  onClose,
  onApply,
  onReset,
}) => {
  if (!isOpen) return null;

  const handleToggle = (type: string) => {
    if (type === "transactions") {
      setSelectedType("transactions");
    } else if (type === "blocks") {
      setSelectedType("blocks");
    } else {
      setSelectedType("");
    }
  };
  return (
    <>
      <div
        className="fixed inset-0 z-40 bg-black bg-opacity-50"
        onClick={onClose}
      />
      <div className="absolute w-full items-center justify-center bg-opacity-50 left-1/2 top-[350px] z-50 flex -translate-x-1/2 -translate-y-1/2 transform flex-col">
        <div
          className="bg-background p-6 rounded-lg w-[80%] max-w-4xl space-y-4"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-primaryLight text-lg">Filters</h2>
            <img
              className="w-5 h-5 cursor-pointer"
              onClick={onClose}
              src={Images.alertCancel}
              alt="Close"
            />
          </div>

          {/* Add the filter form here */}
          <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            <div className="flex items-center gap-2 flex-1">
              <img className="w-6 h-6" src={Images.worldIcon} alt="" />
              <div className="flex items-center gap-2 w-full ">
                <select
                  className="bg-backgroundLight p-2 rounded-md w-full text-primaryLight"
                  value={selectedFrom}
                  onChange={(e) => setSelectedFrom(e.target.value)}
                >
                  <option defaultValue={""}>From</option>
                  <option value="56">BSC</option>
                  <option value="8453">BASE</option>
                  <option value="42161">ARBITRUM</option>
                </select>
              </div>

              <div className="flex items-center gap-2 w-full">
                <select
                  className="bg-backgroundLight p-2 rounded-md w-full text-primaryLight"
                  value={selectedTo}
                  onChange={(e) => setSelectedTo(e.target.value)}
                >
                  <label defaultValue={"FERRUM"}>To</label>
                  <option value="26100">FERRUM</option>
                </select>
              </div>
            </div>
            <div className="flex items-center text-center mx-auto flex-1 gap-2">
              <img className="w-6 h-6" src={Images.dateIcon} alt="" />
              <div className="flex items-center gap-2 w-full">
                <DatePicker
                  placeholderText="Start Date"
                  showTimeSelect
                  className="bg-backgroundLight p-2 rounded-md w-full text-primaryLight"
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                  dateFormat="dd-mm-yyyy h:mm aa"
                />
              </div>

              <div className="flex items-center gap-2 w-full">
                <DatePicker
                  placeholderText="End Date"
                  showTimeSelect
                  className="bg-backgroundLight p-2 rounded-md w-full text-primaryLight"
                  selected={endDate}
                  onChange={(date: any) => setEndDate(date)}
                  dateFormat="dd-mm-yyyy h:mm aa"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex items-center gap-10 flex-1">
              <div className="flex items-center gap-2">
                <span className="text-primaryLight">Transactions</span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    defaultValue={""}
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={selectedType === "transactions"}
                    onClick={() =>
                      handleToggle(
                        selectedType === "transactions" ? "" : "transactions"
                      )
                    }
                  />
                  <div className="w-12 h-5 bg-backgroundLight peer-focus:outline-0 peer-focus:ring-transparent rounded-full peer transition-all ease-in-out duration-500 peer-checked:after:translate-x-full peer-checked:after:bg-primary after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-[22px] after:transition-all"></div>
                </label>
              </div>

              <div className="flex items-center gap-2">
                <span className="text-primaryLight">Blocks</span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    defaultValue={""}
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={selectedType === "blocks"}
                    onClick={() =>
                      handleToggle(selectedType === "blocks" ? "" : "blocks")
                    }
                  />
                  <div className="w-12 h-5 bg-backgroundLight peer-focus:outline-0 peer-focus:ring-transparent rounded-full peer transition-all ease-in-out duration-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-[22px] after:transition-all peer-checked:after:bg-primary"></div>
                </label>
              </div>
            </div>

            <div className="flex justify-end gap-12 mt-6 flex-1">
              <button
                onClick={onReset}
                className="text-primaryLight hover:text-white"
              >
                Reset
              </button>
              <button
                onClick={onApply}
                className="bg-primary text-gray-900 p-2 rounded-md px-10 py-1"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterDialog;
