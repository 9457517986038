import React from "react";
import { Images } from "../assets";
import { Link } from "react-router-dom";
import { Block } from "../interfaces/index";
import { getTimeAgo, timeAgo, truncateMiddleAddress } from "../utils/helper";
import { LatestBlockOrTransactionSkeleton } from "./skeleton/Index";

interface LatestBlocksProps {
  blocks: Block[];
  isLoading: boolean;
}

const LatestBlocks: React.FC<LatestBlocksProps> = ({ blocks, isLoading }) => {
  return (
    <div className="flex flex-col justify-between gap1 bg-backgroundLight text-primaryLight rounded-xl p-6 max-w-2xl font-ModAberMano border border-borderBackgroundLight">
      <div className="flex justify-between items-center pb-4 border-b border-borderBackgroundLight">
        <h2 className="text-lg font-semibold">Latest Blocks</h2>
        <Link to="/blocks">
          <button className="text-sm text-primaryLight hover:underline">
            View All Blocks
          </button>
        </Link>
      </div>
      <ul className="divide-y divide-borderBackgroundLight border-b border-borderBackgroundLight mt-2 ">
        {isLoading ? (
          <LatestBlockOrTransactionSkeleton />
        ) : (
          blocks.slice(0, 5).map((block) => (
            <li
              key={block.hash}
              className="py-[17px] flex items-center justify-between "
            >
              <div className="flex items-center space-x-7 flex-1">
                <div className="w-10 h-10  bg-[#00000040] rounded-full flex items-center justify-center">
                  <img src={Images.frmLogo} alt="transaction" className="" />
                </div>
                {/* <div className="relative">
                  <div className="w-6 h-6 border border-[#F4BB2B] rounded-full flex items-center justify-center">
                    <img src={Images.bscIcon} alt="Block" className="" />
                  </div>
                  <div className="w-6 h-6 border border-[#ffff] rounded-full flex items-center justify-center absolute top-2 left-5">
                    <img src={Images.frmLogo} alt="Block" className="" />
                  </div>
                </div> */}
                <div>
                  <Link to={`block/${block.number}`}>
                    <div className="text-primary font-ModAberMano">
                      {block.number}
                    </div>
                  </Link>
                  <div className="text-xs text-primaryLight">
                    {getTimeAgo(block?.timestamp)}
                  </div>
                </div>
              </div>
              <div className="flex flex-col flex-1">
                <div className="text-sm flex items-center ml-[53px] ">
                  <span className="text-primaryLight text-sm">Miner:</span>
                  <div className="flex gap-2 ml-2">
                    {/* <img className="w-4" src={Images.bscIcon} alt="" /> */}
                    <span className="text-sm">
                      {truncateMiddleAddress(block.miner, 14, 7)}
                    </span>
                  </div>
                </div>
                <div className="flex justify-between mt-[2px] ml-14 ">
                  <div className="space-x-4">
                    <span className="text-primary font-bold ">
                      {block?.txCount} Txns
                    </span>
                    {/* deprected */}
                    <span className="hidden text-primaryLight text-[10px] ">
                      in {"--"}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
      <Link to="/blocks">
        <div className="flex items-center justify-center mt-4  text-center  ">
          <img src={Images.plusIcon} alt="" />
          <span className="text-center hover:underline cursor-pointer">
            View All Blocks
          </span>
        </div>
      </Link>
    </div>
  );
};

export default LatestBlocks;
