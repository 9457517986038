import React, { useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
import ConnectWalletDialog from "./ConnectWalletDialog";

const ConnectWalletButton: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { isConnected, address: walletAddress } = useAccount();
  const { disconnect } = useDisconnect();

  const handleConnectClick = () => {
    setShowDialog(true);
  };

  const handleDisconnectClick = () => {
    disconnect();
  };

  return (
    <div>
      {!isConnected ? (
        <button
          className="bg-background px-6 py-1.5 cursor-pointer rounded-md"
          onClick={handleConnectClick}
        >
          Connect to Web3
        </button>
      ) : (
        <button
          className="bg-background px-6 py-1.5 cursor-pointer rounded-md text-sm"
          onClick={handleDisconnectClick}
        >
          Disconnect from {walletAddress}
        </button>
      )}
      <ConnectWalletDialog
        show={showDialog}
        onHide={() => setShowDialog(false)}
      />
    </div>
  );
};

export default ConnectWalletButton;
