import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { FERRUM_CHAIN_ID } from "../utils/contant";

const NetworkError: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { chainId, isConnected } = useAccount();

  useEffect(() => {
    if (isConnected && chainId !== FERRUM_CHAIN_ID) {
      setErrorMessage(
        `Connected to the wrong network. Please switch to the Ferrum Network.`
      );
    } else {
      setErrorMessage(null);
    }
  }, [isConnected, chainId]);

  return (
    errorMessage && (
      <div className="text-red-500 mb-4">
        {errorMessage}
        {/* Optional button for switching network */}
        {/* <button className="ml-2 bg-primary text-primaryContrast px-4 py-1 rounded-md">
          Switch to Ferrum Network
        </button> */}
      </div>
    )
  );
};

export default NetworkError;
