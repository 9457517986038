import React from "react";
import ContractCard from "../components/ContractCard";

const ContractList: React.FC = () => {
  const contracts = [
    {
      address: "0x3d2e3a9a9b8a9d1a2c9b2f7d967a62f392bf2702",
      details:
        "from: ToBeRevealed.blockwatch.cc<ToBeRevealed.blockwatch.cc> ETH:ETH USD:USD Public Contract SPELL...",
      contractName: "Contract Name",
      lastUpdated: "12/12/2023",
      txns: "5 Txns",
    },
    {
      address: "0x3d2e3a9a9b8a9d1a2c9b2f7d967a62f392bf2702",
      details:
        "from: ToBeRevealed.blockwatch.cc<ToBeRevealed.blockwatch.cc> ETH:ETH USD:USD Public Contract SPELL...",
      contractName: "Contract Name",
      lastUpdated: "12/12/2023",
      txns: "5 Txns",
    },
    {
      address: "0x3d2e3a9a9b8a9d1a2c9b2f7d967a62f392bf2702",
      details:
        "from: ToBeRevealed.blockwatch.cc<ToBeRevealed.blockwatch.cc> ETH:ETH USD:USD Public Contract SPELL...",
      contractName: "Contract Name",
      lastUpdated: "12/12/2023",
      txns: "5 Txns",
    },
    {
      address: "0x3d2e3a9a9b8a9d1a2c9b2f7d967a62f392bf2702",
      details:
        "from: ToBeRevealed.blockwatch.cc<ToBeRevealed.blockwatch.cc> ETH:ETH USD:USD Public Contract SPELL...",
      contractName: "Contract Name",
      lastUpdated: "12/12/2023",
      txns: "5 Txns",
    },
    {
      address: "0x3d2e3a9a9b8a9d1a2c9b2f7d967a62f392bf2702",
      details:
        "from: ToBeRevealed.blockwatch.cc<ToBeRevealed.blockwatch.cc> ETH:ETH USD:USD Public Contract SPELL...",
      contractName: "Contract Name",
      lastUpdated: "12/12/2023",
      txns: "5 Txns",
    },
  ];

  return (
    <div className=" w-full">
      <div className="space-y-4">
        {contracts.map((contract, index) => (
          <ContractCard key={index} {...contract} />
        ))}
      </div>
    </div>
  );
};

export default ContractList;
