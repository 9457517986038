import React from "react";
import { Images } from "../assets";
import { Link } from "react-router-dom";
import { Block } from "../interfaces";
import { getTimeAgo, timeAgo, truncateTxHash } from "../utils/helper";
import { TableSkeleton } from "../components/skeleton/Index";
interface BlockListProps {
  blockList: Block[];
  isLoading?: boolean;
}

const BlocksTable: React.FC<BlockListProps> = ({
  blockList,
  isLoading = false,
}) => {
  return (
    <>
      <div className="flex justify-between text-primaryLight items-center font-ModAberMano my-7">
        <div className="flex items-center gap-2.5">
          <img src={Images.qpLogoIcon} alt={"gplogo"} />
          <span className="font-bold text-lg">Blocks</span>
        </div>
        <p className="hidden text-[10px] font-normal">
          Block #25127915 to #25127939 (Total of 25,127,940 blocks)
        </p>
      </div>

      <div className="overflow-x-auto px-7 py-4 border border-borderBackgroundLight rounded-[20px] font-ModAberMano bg-backgroundLight">
        <table className="min-w-full text-primaryLight">
          <thead>
            <tr className="text-left">
              <th className="p-2.5 text-base font-medium">Block</th>
              <th className="p-2.5 text-base font-medium">Age</th>
              <th className="p-2.5 text-base font-medium">TXN</th>
              <th className="p-2.5 text-base font-medium">MINER</th>
              <th className="p-2.5 text-base font-medium">GAS USED</th>
              <th className="p-2.5 text-base font-medium">GAS LIMIT</th>
              <th className="p-2.5 text-base font-medium">REWARD</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableSkeleton />
            ) : (
              blockList.map((block, index) => (
                <tr
                  key={block.hash}
                  className="border-t border-borderBackgroundLight text-xs "
                >
                  <td className="p-2.5 flex items-center gap-10">
                    <div className="flex items-center gap-1">
                      {/* <div className="flex items-center">
                        <img
                          src={Images.bscIcon}
                          alt="icon"
                          className="h-4 w-4 mr-2"
                        />
                        <img
                          src={Images.algorendIcon}
                          alt="icon"
                          className="h-4 w-4 mr-2"
                        />
                      </div> */}
                      <Link to={`/block/${block.number}`}>
                        <span>{block.number}</span>
                      </Link>
                    </div>
                  </td>
                  <td className="p-2.5 text-xs">
                    {getTimeAgo(block.timestamp)}
                  </td>
                  <td className="p-2.5 text-xs">{block.txCount}</td>
                  <td className="p-2.5 text-xs" key={index}>
                    <span>{truncateTxHash(block.miner)}</span>{" "}
                  </td>
                  <td className="p-2.5 text-xs">{block.gasUsed}</td>
                  <td className="p-2.5 text-xs"> {block.gasLimit}</td>
                  <td className="p-2.5 flex items-center">
                    {block?.txsFees}
                    {/* <img
                    src={Images.frmLogoColorIcon}
                    alt="icon"
                    className="h-4 w-4 mr-2"
                  /> */}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BlocksTable;
