import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchContractInfo = async (address: string) => {
  const url = `${
    process.env.REACT_APP_SERVER_URL
  }/contracts/${address.toLowerCase()}`;

  const { data } = await axios.get(url);

  return data;
};

export const useContractInfo = (address: string) => {
  return useQuery({
    queryKey: ["contracts", address],
    queryFn: () => fetchContractInfo(address),
    enabled: !!address,
  });
};
const fetchContractDetailsInfo = async (address: string) => {
  const url = `${
    process.env.REACT_APP_SERVER_URL
  }/contracts/${address.toLowerCase()}`;

  const { data } = await axios.get(url);

  return data;
};

export const useContractDetailsInfo = (address: string) => {
  return useQuery({
    queryKey: ["contracts", address],
    queryFn: () => fetchContractDetailsInfo(address),
    enabled: !!address,
  });
};
