import React from "react";
import { Images } from "../../assets";
import { Button } from "../../components/ui/Button";

const ConnectedAccount: React.FC<{ title: String }> = ({ title }) => {
  return (
    <div className="p-6 bg-backgroundLight rounded-[20px] text-primaryLight">
      <h2 className="text-lg font-medium uppercase text-primaryLight pb-1 mb-4 border-b border-borderBackgroundLight">
        Connected Account
      </h2>
      {title === "staker" ? (
        <>
          <div className="mb-3.5 flex items-center gap-3 ">
            <span className="font-medium uppercase text-primary ">
              Your Address:
            </span>
            <span className="font-normal">0x4bba9b6bdfa66107...aa68b04a4d</span>
          </div>
          <div className="mb-3.5 flex items-center gap-3 ">
            <span className="font-medium uppercase text-primary">
              Delegated Address:
            </span>
            <span className="font-normal">0x4bba9b6bdfa66107...aa68b04a4d</span>
            <img src={Images.explorerIcon} alt="" />
          </div>
          <div className="mb-3.5 space-x-2">
            <span className="font-medium uppercase text-primary">
              Your Staked Amount:
            </span>
            <span className="font-normal">100.000 FRM</span>
          </div>
          <div className="mb-3.5 space-x-2">
            <span className="font-medium uppercase text-primary">
              Your Rewards:
            </span>
            <span className="font-normal">55.923 FRM</span>
          </div>
          <Button
            title="No Pending Withdrawals At The Moment"
            variant="secondary"
            className="!text-xs !font-normal"
          />
        </>
      ) : title === "miner" ? (
        <>
          <div className="mb-3.5 flex items-center gap-3 ">
            <span className="font-medium uppercase text-primary ">
              Your Address:
            </span>
            <span className="font-normal">0x4bba9b6bdfa66107...aa68b04a4d</span>
          </div>
          <div className="mb-3.5 flex items-center gap-3 ">
            <span className="font-medium uppercase text-primary">
              Assigned Operator Address:
            </span>
            <span className="font-normal">0x4bba9b6bdfa66107...aa68b04a4d</span>
            <img src={Images.explorerIcon} alt="" />
          </div>
          <div className="mb-3.5 space-x-2">
            <span className="font-medium uppercase text-primary">
              Stakes delegated to you:
            </span>
            <span className="font-normal">100.000 FRM</span>
          </div>
          <div className="mb-3.5 space-x-2">
            <span className="font-medium uppercase text-primary">
              Miner index:
            </span>
            <span className="font-normal">55.923 FRM</span>
          </div>
          <div className="mb-3.5 space-x-2">
            <span className="font-medium uppercase text-primary">
              Operator Registered:
            </span>
            <span className="font-normal">false FRM</span>
          </div>
          <div className="mb-3.5 space-x-2">
            <span className="font-medium uppercase text-primary">
              Enough stake to be a miner?
            </span>
            <Button
              title="Yes"
              variant="secondary"
              className="!text-xs !font-semibold !text-successColorContrast !py-1 !px-4 !bg-successColor"
            />
          </div>
          <div className=" w-full space-y-1">
            <label className=" mb-1 text-sm">Miner Operator</label>
            <div className="flex items-center bg-backgroundDark py-1 rounded-md  ">
              <input
                type="text"
                className="px-4 bg-backgroundDark w-[80%] text-primaryLight focus:outline-none"
                placeholder="Address"
              />
              <Button
                title="ASSIGN - ONLY MINER"
                variant="secondary"
                className="!text-xs !px-2 !py-3 w-[190px]"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mb-3.5 flex items-center gap-3 ">
            <span className="font-medium uppercase text-primary ">
              Your Address:
            </span>
            <span className="font-normal">0x4bba9b6bdfa66107...aa68b04a4d</span>
          </div>
          <div className="mb-3.5 flex items-center gap-3 ">
            <span className="font-medium uppercase text-primary">
              Registered:
            </span>
            <span className="font-normal">false FRM</span>
            <img src={Images.explorerIcon} alt="" />
          </div>

          <div className="mt-4 w-full">
            <Button
              variant="primary"
              title="REGISTER OPERATOR"
              className="w-full"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ConnectedAccount;
