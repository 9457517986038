import React, { useState } from "react";
import Accordion from "../../components/Accordion";
import { Images } from "../../assets";
import { useAccount, useWriteContract } from "wagmi";
import ConnectWalletDialog from "../../components/ConnectWalletDialog";
import { BaseError } from "viem";
import ConnectWalletButton from "../../components/ConnectWalletButton";
import NetworkError from "../../components/NetworkError";

interface ABIInput {
  name: string;
  type: string;
}

interface ABIItem {
  type: string;
  name?: string;
  constant?: boolean;
  stateMutability?: string;
  inputs: ABIInput[];
}

interface WriteMethodsProps {
  abi: ABIItem[];
  contractAddress: string;
}

const WriteMethods: React.FC<WriteMethodsProps> = ({
  abi,
  contractAddress,
}) => {
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
  const { isConnected, address: walletAddress } = useAccount();
  const { data, error, writeContract, isPending } = useWriteContract();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  // Filter write methods
  const writeMethods = abi?.filter(
    (item) =>
      item.type === "function" &&
      !item.constant &&
      !item.stateMutability?.includes("view")
  );

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    setInputValues({
      ...inputValues,
      [inputName]: e.target.value,
    });
  };

  const handleWriteClick = (methodName: string) => {
    const selectedMethod = writeMethods.find(
      (method) => method.name === methodName
    );

    if (selectedMethod) {
      setSelectedMethod(methodName);

      writeContract({
        address: contractAddress as `0x${string}`,
        abi,
        functionName: methodName,
        args: selectedMethod.inputs.map(
          (input) => inputValues[input.name] || ""
        ),
      });
    }
  };
  const handleConnectClick = () => {
    setShowDialog(true);
  };
  return (
    <div className="text-primaryLight p-8">
      <div className="flex items-center justify-between gap-2 text-base font-medium">
        <div className="flex items-center  gap-2">
          <img src={Images.bineryIcon} alt="binary icon" />
          <span>Read As Proxy Contract</span>
        </div>
        <ConnectWalletButton />
      </div>
      <NetworkError />

      {writeMethods.length === 0 ? (
        <p>No Proxy methods available.</p>
      ) : (
        writeMethods.map((method, index) => (
          <Accordion
            key={method.name || index}
            title={`${index + 1}. ${method.name || "Unnamed Method"}`}
          >
            {method.inputs && method.inputs.length > 0 ? (
              method.inputs.map((input, idx) => (
                <div key={idx} className="space-y-2">
                  <label className="block text-sm text-primary font-medium mt-1">
                    {input.name} ({input.type})
                  </label>
                  <input
                    type="text"
                    className="w-full outline-none p-2 bg-backgroundDark text-primaryLight border border-borderBackgroundLight rounded-md"
                    placeholder={`${input.name} (${input.type})`}
                    value={inputValues[input.name] || ""}
                    onChange={(e) => handleInputChange(e, input.name)}
                  />
                </div>
              ))
            ) : (
              <p className="text-sm text-primary">This method has no inputs.</p>
            )}
            <button
              className="bg-primary py-1 px-4 rounded-md text-primaryContrast font-semibold my-4"
              onClick={() => handleWriteClick(method.name!)}
            >
              WRITE {method.name?.toUpperCase() || "METHOD"}
            </button>
            {selectedMethod === method.name && (
              <div className="mt-2">
                {error && (
                  <p>Error occurred {`${(error as BaseError).shortMessage}`}</p>
                )}
                {<p>{!isPending && JSON.stringify(data)}</p>}
              </div>
            )}
          </Accordion>
        ))
      )}
      <ConnectWalletDialog
        show={showDialog}
        onHide={() => setShowDialog(false)}
      />
    </div>
  );
};

export default WriteMethods;
