import React from "react";
import { Images } from "../assets";

const ActionButtons: React.FC = () => {
  return (
    <div className="flex flex-col items-center  font-ModAberMano mt-20">
      <div className="mb-4 w-[377px]">
        <div className="bg-background flex items-center justify-between p-2 rounded-md text-center border border-borderBackgroundLight">
          <div>
            <input type="checkbox" className=" w-10" />
            <span className="text-primaryLight text-lg font-sans">
              I'm not a robot
            </span>
          </div>
          <div className=" flex flex-col items-center ">
            <img className="w-9" src={Images.recaptcha} alt="" />
            <span className="text-xs text-primaryLight">reCAPTCHA</span>
            <span className="text-[10px] text-primaryLight">
              Privacy -Terms
            </span>
          </div>
        </div>
      </div>
      <div className="flex max-w-[500px]  space-x-4 mt-10">
        <button className="p-3 w-[280px] bg-primary text-black font-bold rounded-md  transition-colors">
          Verify and Publish
        </button>
        <button className="p-3 w-[280px] bg-backgroundLight text-white rounded-md hover:bg-gray-700 transition-colors">
          Reset
        </button>
      </div>
    </div>
  );
};

export default ActionButtons;
