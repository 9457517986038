import React from "react";
import MainLayout from "../../layout/MainLayout";
import { Images } from "../../assets";
import SourceNetworkCard from "./SourceNetworkCard";
import SourceBlockCard from "./SourceBlockCard";
import TransactionLifeCycleHeader from "./TransactionLifeCycleHeader";
import SearchBar from "../../components/SearchBar";
const Index: React.FC = () => {
  return (
    <MainLayout>
      <SearchBar />

      <div className="max-w-6xl mx-auto mb-16">
        {/* header  */}
        <div className="flex items-center gap-2 font-ModAberMano my-6">
          <img src={Images.qpLogoIcon} alt="" />{" "}
          <span className=" text-lg font-medium text-primaryLight">
            {" "}
            Transaction Lifecycle
          </span>
        </div>
        <div className=" grid grid-cols-1 md:grid-cols-2 ">
          <SourceNetworkCard data={{ title: "Source Network" }} />
          <SourceBlockCard data={{ title: "Source Block" }} />
        </div>
        <TransactionLifeCycleHeader />
      </div>
    </MainLayout>
  );
};

export default Index;
