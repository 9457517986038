import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { Images } from "../assets";
import TransactionDetailsCard from "../components/TransactionDetailsCard";
import { useTransactionById } from "../hooks";
import SearchBar from "../components/SearchBar";
import { TransactionSkeleton } from "../components/skeleton/TransactionSkeleton";

const TransactionDetails: React.FC = () => {
  const { id: txId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: singleTransaction,
    isLoading,
    error,
  } = useTransactionById(txId || "");

  // Set the active tab from the query string or default to "overview"
  const [activeTab, setActiveTab] = useState<"overview" | "logs">(() => {
    const query = new URLSearchParams(location.search);
    const tab = query.get("tab") as "overview" | "logs";
    return tab || "overview";
  });

  // Handle tab switching and update the URL
  const handleTabSwitch = (tab: "overview" | "logs") => {
    setActiveTab(tab);
    navigate(`/tx/${txId}?tab=${tab}`);
  };

  // Ensure the default tab is "overview" if no tab is present in the URL
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tab = query.get("tab");

    if (!tab) {
      navigate(`/tx/${txId}?tab=overview`, { replace: true });
    }
  }, [location.search, navigate, txId]);

  if (!txId) {
    return <div>Error: Transaction ID is missing.</div>;
  }

  if (error) {
    return <div>Error fetching transaction details.</div>;
  }

  return (
    <MainLayout>
      <SearchBar />
      <div className="max-w-6xl mx-auto my-16">
        <div className="flex items-center gap-2 justify-between text-primaryLight font-ModAberMano my-7 relative">
          <div className="flex items-center gap-2">
            <span>Transaction Details</span>
            <div className="ml-8 hidden items-center gap-1">
              <img
                className="p-2 rounded-md bg-backgroundLight cursor-pointer"
                onClick={() => handleTabSwitch("overview")}
                src={Images.leftArrow}
                alt="Left Arrow"
              />
              <img
                className="p-2 rounded-md bg-backgroundLight cursor-pointer"
                onClick={() => handleTabSwitch("logs")}
                src={Images.rightArrow}
                alt="Right Arrow"
              />
            </div>
          </div>
          <div className="absolute right-4 top-[22px]">
            <button
              className={`px-3 py-1 rounded-tr-md ${
                activeTab === "overview"
                  ? "bg-primary text-primaryContrast"
                  : "bg-borderBackgroundLight text-primaryLight"
              }`}
              onClick={() => handleTabSwitch("overview")}
            >
              Overview
            </button>
            <button
              className={`px-4 py-1 rounded-tr-md ${
                activeTab === "logs"
                  ? "bg-primary text-primaryContrast"
                  : "bg-borderBackgroundLight text-primaryLight"
              }`}
              onClick={() => handleTabSwitch("logs")}
            >
              Logs
            </button>
          </div>
        </div>
        {isLoading ? (
          <TransactionSkeleton />
        ) : (
          <TransactionDetailsCard
            tx={singleTransaction}
            handleTabSwitch={handleTabSwitch}
            activeTab={activeTab}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default TransactionDetails;
