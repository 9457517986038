import React, { useEffect, useState } from "react";
import { Images, ReactIcons } from "../assets";
import FilterDialog from "./FilterDialog";
import { useSearchResults } from "../hooks/index";
import { useNavigate } from "react-router-dom";
import BlocksTable from "./BlocksTable";

const SearchBar: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [shouldSearch, setShouldSearch] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [selectedFrom, setSelectedFrom] = useState<string>("");
  const [selectedTo, setSelectedTo] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [isFilterSet, setIsFilterSet] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const navigate = useNavigate();

  const {
    data: searchResults,
    isLoading,
    error,
  } = useSearchResults(searchQuery, shouldSearch);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  const handleSearch = () => {
    const query: any = {};

    // Build the query based on the search value and selected filters
    if (searchValue.trim()) query.data = searchValue.trim().toLowerCase();
    if (selectedFrom) query.fromNetwork = selectedFrom;
    if (selectedTo) query.toNetwork = selectedTo;
    if (selectedType) query.type = selectedType;
    if (startDate) query.fromDate = startDate.toISOString();
    if (endDate) query.toDate = endDate.toISOString();

    // If the search value looks like a valid address (basic check for 42 char address)
    if (searchValue.startsWith("0x") && searchValue.length === 42) {
      navigate(`/address/${searchValue}`);
      return; // Exit after navigating to the address page
    }

    // If any filter or search data is set, trigger the search
    if (query.data || Object.keys(query).length > 0) {
      setSearchQuery(query);
      setShouldSearch(true);
    }

    setIsDialogOpen(false); // Close the dialog if filters were applied
  };

  const handleResetFilters = () => {
    setIsDialogOpen(false);
    setIsFilterSet(false);
    setSelectedFrom("");
    setSelectedTo("");
    setSelectedType("");
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    if (searchResults !== undefined) {
      setShouldSearch(false);

      if (Array.isArray(searchResults?.results)) {
        if (searchResults.results.length > 0) {
          if (searchResults.results[0]?.blockNumber !== undefined) {
            navigate(`/txs`, {
              state: { filteredData: searchResults.results },
            });
          } else {
            navigate(`/blocks`, {
              state: { filteredData: searchResults.results },
            });
          }
        }
      } else if (searchResults.hasOwnProperty("number")) {
        navigate(`/block/${searchResults.hash}`);
      } else if (searchResults.hasOwnProperty("from")) {
        navigate(`/tx/${searchResults.hash}`);
      }
    }
  }, [searchResults, navigate]);

  // Generate a string with the selected filters to display
  const selectedFiltersMessage = () => {
    const filters = [];
    const networkMapping: { [key: string]: string } = {
      "42161": "ARBITRUM",
      "56": "BSC",
      "8453": "BASE",
    };

    const getNetworkName = (networkId: string) =>
      networkMapping[networkId] || "";

    const selectedFromDisplay = `${getNetworkName(selectedFrom)}`;
    if (selectedFrom) filters.push(`From: ${selectedFromDisplay}`);
    if (selectedTo) filters.push(`To: ${selectedTo}`);
    if (selectedType) filters.push(`Type: ${selectedType}`);
    if (startDate)
      filters.push(`Start Date: ${startDate.toLocaleDateString()}`);
    if (endDate) filters.push(`End Date: ${endDate.toLocaleDateString()}`);

    if (filters.length > 0) {
      return `You have selected: ${filters.join(", ")}`;
    } else {
      return "No filters selected.";
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 font-ModAberMano flex flex-col gap-4 my-10">
      <p className="font-ModAberMano font-semebold text-xl text-primaryLight uppercase">
        Quantum Portal Explorer
      </p>
      <div className="flex items-center bg-background rounded-md border-[1.7px] border-primary px-1 py-1.5">
        <button
          onClick={handleOpenDialog}
          className="flex items-center text-primaryLight bg-backgroundLight rounded-md px-6 py-1.5 mr-2"
        >
          <span className="mr-2">
            <img className="w-3" src={Images.searchFilter} alt="" />
          </span>
          <span className="text-primary text-sm font-normal">All Filters</span>
        </button>
        <input
          type="text"
          placeholder="Search By Address / Tx ID / Block / Token"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyPress={handleKeyPress} // Only search on Enter
          className="flex-grow bg-transparent text-primaryLight text-base placeholder:text-primaryLight focus:outline-none px-2"
        />
        <ReactIcons.CiSearch
          className="text-primborder-primary text-3xl text-primary mr-2 cursor-pointer"
          onClick={handleSearch} // Trigger search on icon click
        />
      </div>

      {/* Display the selected filters message below the search bar */}
      <div className="hidden mt-2">
        <p className=" text-primary">{selectedFiltersMessage()}</p>
      </div>

      {error && (
        <div className="max-w-6xl mx-auto my-4 text-center font-ModAberMano">
          <p className="text-red-600 text-lg">
            Oops! Unable to fetch details. Please check the block hash or
            transaction hash are valid
          </p>
        </div>
      )}

      <FilterDialog
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedTo={selectedTo}
        setSelectedTo={setSelectedTo}
        selectedFrom={selectedFrom}
        setSelectedFrom={setSelectedFrom}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onApply={handleSearch}
        onReset={handleResetFilters}
      />
    </div>
  );
};

export default SearchBar;
