import React, { useState } from "react";
import Accordion from "../../components/Accordion";
import { Images } from "../../assets";
import ConnectWalletDialog from "../../components/ConnectWalletDialog";
import { useAccount, useReadContract } from "wagmi";
import { BaseError } from "viem";
import ConnectWalletButton from "../../components/ConnectWalletButton";
import NetworkError from "../../components/NetworkError";

interface ABIInput {
  name: string;
  type: string;
}

interface ABIItem {
  type: string;
  name?: string;
  constant?: boolean;
  stateMutability?: string;
  inputs: ABIInput[];
}

interface ReadMethodsProps {
  abi: ABIItem[];
  contractAddress: string;
}

const ReadMethods: React.FC<ReadMethodsProps> = ({ abi, contractAddress }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
  const { isConnected, address: walletAddress } = useAccount();

  // Filter read methods, only considering those with names and ignoring events or constructor
  const readMethods = abi?.filter(
    (item) =>
      item.type === "function" &&
      item.name &&
      (item.constant || item.stateMutability?.includes("view"))
  );

  // Call the method using useContractRead
  const { data, isError, isLoading, refetch, error } = useReadContract({
    address: contractAddress as `0x${string}`,
    abi,
    functionName: selectedMethod || "",
    args: selectedMethod
      ? readMethods
          .find((method) => method.name === selectedMethod)
          ?.inputs.map((input) => inputValues[input.name] || "")
      : [],
  });

  const handleConnectClick = () => {
    setShowDialog(true);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    setInputValues({
      ...inputValues,
      [inputName]: e.target.value,
    });
  };

  const handleReadClick = (methodName: string) => {
    setSelectedMethod(methodName);
  };

  const formatOutput = (data: any) => {
    if (typeof data === "object") {
      return (
        <div className="overflow-x-auto">
          <pre className="bg-gray-800 text-white p-4 rounded-md">
            {JSON.stringify(data, null, 2)}
          </pre>
        </div>
      );
    }
    return <span className="text-primary font-mono">{data}</span>;
  };

  // Trigger the refetch on button click
  const handleRefetch = () => {
    if (selectedMethod) {
      refetch();
    }
  };

  return (
    <div className="text-primaryLight p-8">
      <div className="flex items-center justify-between gap-2 text-base font-medium">
        <div className="flex items-center  gap-2">
          <img src={Images.bineryIcon} alt="binary icon" />
          <span>Read As Proxy Contract</span>
        </div>
        <ConnectWalletButton />
      </div>
      <NetworkError />

      {readMethods.length === 0 ? (
        <p>No read methods available.</p>
      ) : (
        readMethods.map((method, index) => (
          <Accordion
            key={method.name || index}
            title={`${index + 1}. ${method.name || "Unnamed Method"}`}
          >
            {method.inputs && method.inputs.length > 0 ? (
              method.inputs.map((input, idx) => (
                <div key={idx} className="space-y-2">
                  <label className="block text-sm text-primary font-medium mt-1">
                    {input.name} ({input.type})
                  </label>
                  <input
                    type="text"
                    className="w-full outline-none p-2 bg-backgroundDark text-primaryLight border border-borderBackgroundLight rounded-md"
                    placeholder={`${input.name} (${input.type})`}
                    value={inputValues[input.name] || ""}
                    onChange={(e) => handleInputChange(e, input.name)}
                  />
                </div>
              ))
            ) : (
              <p className="text-sm text-primary">This method has no inputs.</p>
            )}
            <div className="flex flex-col mt-4">
              <button
                className="w-fit bg-primary text-primaryContrast px-4 py-2 my-2 rounded-md"
                onClick={() => {
                  handleReadClick(method.name!);
                  handleRefetch();
                }}
              >
                Read {method.name?.toUpperCase() || "METHOD"}
              </button>
              {selectedMethod === method.name && (
                <div className="mt-2">
                  {error && (
                    <p>
                      Error occurred {`${(error as BaseError).shortMessage}`}
                    </p>
                  )}
                  <div>{!isLoading && formatOutput(data)}</div>
                </div>
              )}
            </div>
          </Accordion>
        ))
      )}
      <ConnectWalletDialog
        show={showDialog}
        onHide={() => setShowDialog(false)}
      />
    </div>
  );
};

export default ReadMethods;
