import React from "react";
import MainLayout from "../layout/MainLayout";
import SearchBar from "../components/SearchBar";
import ContractVerificationForm from "../components/ContractVerificationForm";

const ContractVerifaction: React.FC = () => {
  return (
    <MainLayout>
      <SearchBar />
      <div className="max-w-6xl mx-auto my-16">
        <ContractVerificationForm />
      </div>
    </MainLayout>
  );
};

export default ContractVerifaction;
