import React from "react";

const AdvancedFilter: React.FC = () => {
  return (
    <div className="bg-backgroundLight p-4 rounded-md shadow-md font-ModAberMano border border-borderBackgroundLight">
      <h3 className="text-primaryLight text-sm mb-4 border-b border-borderBackgroundLight pb-2">
        Advanced Filter
      </h3>
      <div className="mb-4">
        <label
          className="block text-primaryLight text-xs mb-2"
          htmlFor="selection"
        >
          Solidity Version
        </label>
        <select
          id="selection"
          className="w-full border  p-1.5 bg-background text-primaryLight text-sm rounded-md border-borderBackgroundLight"
        >
          <option>Select</option>
        </select>
      </div>

      <div className="mb-4">
        <label
          className="block text-primaryLight text-xs mb-2"
          htmlFor="contractAddress"
        >
          Contract Address
        </label>
        <input
          id="contractAddress"
          type="text"
          className="w-full p-1.5 bg-background text-primaryLight text-sm rounded-md border border-borderBackgroundLight"
          placeholder="0x..."
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-primaryLight text-xs mb-2"
          htmlFor="deployerAddress"
        >
          Deployer Address
        </label>
        <input
          id="deployerAddress"
          type="text"
          className="w-full p-1.5 bg-background text-primaryLight text-sm rounded-md border border-borderBackgroundLight"
          placeholder="0x..."
        />
      </div>

      <div className="flex justify-between">
        <div className="mb-4">
          <label
            className="block text-primaryLight text-xs mb-2"
            htmlFor="dateRange"
          >
            Date From
          </label>
          <input
            id="dateRange"
            type="date"
            className="w-full p-1.5 bg-background text-primaryLight text-sm rounded-md border border-borderBackgroundLight"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-primaryLight text-xs mb-2"
            htmlFor="dateRange"
          >
            Date To
          </label>
          <input
            id="dateRange"
            type="date"
            className="w-full p-1.5 bg-background text-primaryLight text-sm rounded-md border border-borderBackgroundLight"
          />
        </div>
      </div>

      <button
        type="button"
        className="w-full p-2 bg-primary text-black font-bold rounded-md transition-colors"
      >
        Apply
      </button>
    </div>
  );
};

export default AdvancedFilter;
