import { format, formatDistanceStrict, formatDistanceToNow } from "date-fns";
import { toZonedTime } from "date-fns-tz";

export const validatorData = [
  {
    rank: "#1",
    name: "Validator Name",
    address: "0x7f9b6c91d3e...",
    firstBlock: 6637184,
    lastBlock: 26435974,
    oneDay: 1189,
    sevenDays: 1189,
    thirtyDays: 1189,
    active: "YES",
  },
  {
    rank: "#2",
    name: "Validator Name",
    address: "0x7f9b6c91d3e...",
    firstBlock: 6637184,
    lastBlock: 26435974,
    oneDay: 1189,
    sevenDays: 1189,
    thirtyDays: 1189,
    active: "YES",
  },
  {
    rank: "#3",
    name: "Validator Name",
    address: "0x7f9b6c91d3e...",
    firstBlock: 6637184,
    lastBlock: 26435974,
    oneDay: 1189,
    sevenDays: 1189,
    thirtyDays: 1189,
    active: "YES",
  },
  {
    rank: "#4",
    name: "Validator Name",
    address: "0x7f9b6c91d3e...",
    firstBlock: 6637184,
    lastBlock: 26435974,
    oneDay: 1189,
    sevenDays: 1189,
    thirtyDays: 1189,
    active: "YES",
  },
  {
    rank: "#5",
    name: "Validator Name",
    address: "0x7f9b6c91d3e...",
    firstBlock: 6637184,
    lastBlock: 26435974,
    oneDay: 1189,
    sevenDays: 1189,
    thirtyDays: 1189,
    active: "YES",
  },
  {
    rank: "#6",
    name: "Validator Name",
    address: "0x7f9b6c91d3e...",
    firstBlock: 6637184,
    lastBlock: 26435974,
    oneDay: 1189,
    sevenDays: 1189,
    thirtyDays: 1189,
    active: "YES",
  },
  {
    rank: "#1",
    name: "Validator Name",
    address: "0x7f9b6c91d3e...",
    firstBlock: 6637184,
    lastBlock: 26435974,
    oneDay: 1189,
    sevenDays: 1189,
    thirtyDays: 1189,
    active: "YES",
  },
  {
    rank: "#1",
    name: "Validator Name",
    address: "0x7f9b6c91d3e...",
    firstBlock: 6637184,
    lastBlock: 26435974,
    oneDay: 1189,
    sevenDays: 1189,
    thirtyDays: 1189,
    active: "YES",
  },
  {
    rank: "#1",
    name: "Validator Name",
    address: "0x7f9b6c91d3e...",
    firstBlock: 6637184,
    lastBlock: 26435974,
    oneDay: 1189,
    sevenDays: 1189,
    thirtyDays: 1189,
    active: "YES",
  },
  {
    rank: "#1",
    name: "Validator Name",
    address: "0x7f9b6c91d3e...",
    firstBlock: 6637184,
    lastBlock: 26435974,
    oneDay: 1189,
    sevenDays: 1189,
    thirtyDays: 1189,
    active: "YES",
  },
  {
    rank: "#1",
    name: "Validator Name",
    address: "0x7f9b6c91d3e...",
    firstBlock: 6637184,
    lastBlock: 26435974,
    oneDay: 1189,
    sevenDays: 1189,
    thirtyDays: 1189,
    active: "YES",
  },
  // Add more validators as needed
];

export const truncateTxHash = (
  hash: string | null | undefined,
  length = 12
): string => {
  if (!hash || hash.length === 0) return "N/A"; // Handle null, undefined, or empty hash
  if (hash.length <= length) return hash;
  return `${hash.slice(0, length)}...`;
};

export const truncateMiddleAddress = (
  address: string | undefined | null, // Allow undefined or null values
  startLength = 6,
  endLength = 8
) => {
  // Return empty string or a fallback if address is invalid
  if (!address || typeof address !== "string") return "";

  // Check if the address is shorter than or equal to the combined start and end lengths
  if (address.length <= startLength + endLength) return address;

  return `${address.slice(0, startLength)}...${address.slice(-endLength)}`;
};

export function timeAgo(timestampInSeconds: number): string {
  const currentTime = Math.floor(Date.now() / 1000); // Convert current time to seconds
  const seconds = currentTime - timestampInSeconds;

  if (seconds < 0) return "In the future";

  let interval = Math.floor(seconds / 31536000); // Seconds in a year
  if (interval >= 1) return `${interval} year${interval > 1 ? "s" : ""} ago`;

  interval = Math.floor(seconds / 2592000); // Seconds in a month
  if (interval >= 1) return `${interval} month${interval > 1 ? "s" : ""} ago`;

  interval = Math.floor(seconds / 86400); // Seconds in a day
  if (interval >= 1) return `${interval} day${interval > 1 ? "s" : ""} ago`;

  interval = Math.floor(seconds / 3600); // Seconds in an hour
  if (interval >= 1) return `${interval} hour${interval > 1 ? "s" : ""} ago`;

  interval = Math.floor(seconds / 60); // Seconds in a minute
  if (interval >= 1) return `${interval} minute${interval > 1 ? "s" : ""} ago`;

  if (seconds >= 1) return `${seconds} second${seconds > 1 ? "s" : ""} ago`;

  return "just now";
}

export const formatBlockTimestamp = (timestamp: number) => {
  // Ensure the timestamp is in seconds and convert to milliseconds
  if (!timestamp || typeof timestamp !== "number") {
    return "Invalid Timestamp";
  }

  try {
    // Convert the timestamp from seconds to milliseconds
    const date = new Date(timestamp * 1000);
    // Calculate the "time ago" part with strict formatting
    const timeAgo = formatDistanceStrict(date, new Date(), {
      roundingMethod: "floor",
    })
      .replace("days", "day")
      .replace("hours", "hrs")
      .replace("hour", "hr");

    // Format the exact timestamp with timezone in the required format
    const zonedDate = toZonedTime(date, "UTC");
    const exactDateTime = format(zonedDate, "MMM-dd-yyyy hh:mm:ss a 'UTC'");

    return `${timeAgo} ago (${exactDateTime})`;
  } catch (error) {
    console.error("Error formatting timestamp:", error);
    return "Invalid Timestamp";
  }
};

export const getTimeAgo = (timestamp: string): string => {
  const givenTime = new Date(timestamp);
  const currentTime = new Date();

  const diffInSeconds = Math.floor(
    (currentTime.getTime() - givenTime.getTime()) / 1000
  );

  if (diffInSeconds < 60) {
    return `${diffInSeconds} sec${diffInSeconds !== 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} min${minutes > 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hr${hours > 1 ? "s" : ""} ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  }
};

export const formatTimeAgoWithTimestamp = (timestamp: string): string => {
  const givenTime = new Date(timestamp);
  const currentTime = new Date();
  const diffInSeconds = Math.floor(
    (currentTime.getTime() - givenTime.getTime()) / 1000
  );

  let timeAgo = "";

  const days = Math.floor(diffInSeconds / 86400);
  const hours = Math.floor((diffInSeconds % 86400) / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);

  if (days > 0) {
    timeAgo += `${days} Day${days > 1 ? "s" : ""} `;
  }
  if (hours > 0) {
    timeAgo += `${hours} Hr${hours > 1 ? "s" : ""} `;
  }
  if (days === 0 && hours === 0) {
    timeAgo += `${minutes} Min${minutes > 1 ? "s" : ""} `;
  }

  timeAgo += "Ago";

  // Formatting the original timestamp like (Jan-24-2023 01:46:59 AM +UTC)
  const formattedTimestamp = givenTime
    .toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZoneName: "short",
    })
    .replace(", ", " ");

  return `${timeAgo} (${formattedTimestamp})`;
};

// Example usage:
const timeAgoWithTimestamp = formatTimeAgoWithTimestamp(
  "2024-08-27T08:21:00.000000Z"
);
// Output: "1 Day 17 Hrs Ago (Jan-24-2023 01:46:59 AM +UTC)"
