import React from "react";
import { CodeBlock, atomOneDark } from "react-code-blocks";
import { Images } from "../../assets";
import ContractDetailsHeader from "../../components/ContractDetailsHeader";

// Header Component
const SectionHeader: React.FC<{ title: string; iconSrc: string }> = ({
  title,
  iconSrc,
}) => (
  <div className="flex items-center gap-2 border-b border-borderBackgroundLight pb-2">
    <img src={iconSrc} alt="Section Icon" />
    <p className="text-primaryLight text-base font-medium">{title}</p>
  </div>
);

const CodeSection: React.FC<{
  code: string;
  fileName: string;
  copyIconSrc: string;
  linkIconSrc: string;
}> = ({ code, fileName, copyIconSrc, linkIconSrc }) => (
  <>
    {fileName !== "Contract Creation Code" && (
      <div className="flex justify-between py-3">
        <span className="text-[10px] font-medium"> {fileName.trim()}</span>
        <div className="flex gap-1">
          <img className="cursor-pointer" src={copyIconSrc} alt="Copy Code" />
          <img className="cursor-pointer" src={linkIconSrc} alt="Code Link" />
        </div>
      </div>
    )}
    <div className="bg-backgroundLight rounded-xl mb-8">
      <div
        className="max-h-[400px] overflow-y-auto"
        style={{ borderRadius: "10px", background: "#111315" }}
      >
        <CodeBlock
          text={code}
          showLineNumbers={true}
          theme={atomOneDark}
          language="javascript"
        />
      </div>
    </div>
  </>
);

// Main Component
const ContractCodeInfo: React.FC<{
  contractName: string;
  compilerVersion: string;
  optimization: number;
  sourceCode: string;
  settingsCode: any;
  contractAbi: any;
  creationCode: any;
}> = ({
  contractName,
  compilerVersion,
  optimization,
  sourceCode,
  settingsCode,
  creationCode,
  contractAbi,
}) => {
  return (
    <div className="text-primaryLight min-h-screen">
      <div className="">
        {/* Contract Details Header */}
        <ContractDetailsHeader
          contractName={contractName}
          compilerVersion={compilerVersion}
          optimization={optimization}
          otherSettings={"evm based"} // Convert object to string
        />

        {/* Contract Source Code Section */}
        <SectionHeader
          title="Contract Source Code (Solidity Standard Json-Input Format)"
          iconSrc={Images.ideIcon}
        />
        <CodeSection
          code={sourceCode}
          fileName={` ${contractName}.sol`}
          copyIconSrc={Images.copyCodeIcon}
          linkIconSrc={Images.codeLInkIcon}
        />

        {/* Settings Section */}
        <SectionHeader title="Settings" iconSrc={Images.settingCodeIcon} />
        <CodeSection
          code={JSON.stringify(settingsCode, null, 2)}
          fileName={`setting of ${contractName}`}
          copyIconSrc={Images.copyCodeIcon}
          linkIconSrc={Images.codeLInkIcon}
        />

        {/* Contract Security Audit Section */}
        <SectionHeader
          title="Contract Security Audit"
          iconSrc={Images.ideIcon}
        />
        <CodeSection
          code={JSON.stringify(contractAbi, null, 2)}
          fileName="Contract ABI"
          copyIconSrc={Images.copyCodeIcon}
          linkIconSrc={Images.codeLInkIcon}
        />

        {/* Contract Creation Code Section */}
        <div className="flex justify-between mb-3">
          <div className="flex gap-1 items-center">
            <img src={Images.ideIcon} alt="IDE Icon" />
            <span className="text-[10px] font-medium">
              Contract Creation Code
            </span>
          </div>
          <div className="flex gap-1">
            <img
              className="cursor-pointer"
              src={Images.copyCodeIcon}
              alt="Copy Code"
            />
            <img
              className="cursor-pointer"
              src={Images.codeLInkIcon}
              alt="Code Link"
            />
          </div>
        </div>
        <CodeSection
          code={JSON.stringify(creationCode, null, 2)}
          fileName="Contract Creation Code"
          copyIconSrc={Images.copyCodeIcon}
          linkIconSrc={Images.codeLInkIcon}
        />
      </div>
    </div>
  );
};

export default ContractCodeInfo;
