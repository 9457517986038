import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import TableHeader from "../../components/TableHeader";
import ContractOverviewInfoCard from "../../components/ContractOverviewInfoCard";
import ContractAddressHeader from "../../components/ContractAddressHeader";
import TransactionsTable from "../../components/TransactionsTable";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import {
  useAccountInfo,
  useContractDetailsInfo,
  useContractInfo,
  useInternalTxByAddress,
  useLogsByAddress,
  useTokenTransferByAddress,
  useTransactions,
} from "../../hooks";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ContractDetailsCard from "./ContractDetailsCard";
import { Images } from "../../assets";
import DecodeEvent from "./DecodeEvent";

const TransactionSummery: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [activeId, setActiveId] = useState<number>(1);
  const [contractAddress, setContractAddress] = useState<string | null>(null);
  const { address } = useParams<{ groupId: string; address: `0x${string}` }>();
  const [requestedAddress, setRequestedAddress] = useState<string>(
    address || ""
  );
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: AccountData,
    error: AccountDataError,
    isLoading: accountLoading,
  } = useAccountInfo(address || "");

  const {
    data: transactions,
    error: transactionsError,
    isLoading: transactionsLoading,
  } = useTransactions(currentPage, recordsPerPage, address);
  const {
    data: contractDetails,
    error: contractDetailsError,
    isLoading: contractDetailsLoading,
  } = useContractInfo(address || "");

  const {
    data: contractDetailsInfo,
    error: contractDetailsInfoError,
    isLoading: contractDetailsInfoLoading,
  } = useContractDetailsInfo(
    contractDetails?.implementations[0]?.address || ""
  );

  const {
    data: internalTxs,
    error: internalTxsError,
    isLoading: internalTxsLoading,
  } = useInternalTxByAddress(currentPage, recordsPerPage, address || "");

  const {
    data: tokenTransferTxs,
    error: tokenTransferTxsError,
    isLoading: tokenTransferTxsLoading,
  } = useTokenTransferByAddress(currentPage, recordsPerPage, address || "");

  const {
    data: logs,
    error: logsError,
    isLoading: LogsLoading,
  } = useLogsByAddress(currentPage, recordsPerPage, address || "");

  console.log({ logs });

  const menuItems = [
    { id: 1, label: "Transactions", tab: "transactions" },
    { id: 2, label: "Internal Transactions", tab: "internal-txs" },
    { id: 3, label: "Token Transfers", tab: "token-transfers" },
    { id: 4, label: "Logs", tab: "logs" },
    ...(AccountData?.is_contract && AccountData?.is_verified
      ? [
          {
            id: 5,
            label: "Contract",
            tab: "contract",
            img: Images.successIcon,
          },
        ]
      : []),
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");

    const activeTab = menuItems.find((item) => item.tab === tab);
    if (activeTab) {
      setActiveId(activeTab.id);
    } else if (AccountData?.is_contract) {
      setActiveId(5);
      navigate(`/address/${address}?tab=contract`);
    } else {
      setActiveId(1);
      navigate(`/address/${address}?tab=transactions`);
    }
  }, [AccountData, location.search, address, navigate]);

  const handleTabChange = (id: number, tab: string) => {
    setActiveId(id);
    navigate(`/address/${address}?tab=${tab}`);
  };

  const handlePageChange = (newPage: number) => setCurrentPage(newPage);
  const handleRecordsPerPageChange = (newRecordsPerPage: number) => {
    setRecordsPerPage(newRecordsPerPage);
    setCurrentPage(1);
  };

  console.log(activeId, "actve id ");

  return (
    <MainLayout>
      <SearchBar />
      <div className="max-w-6xl mx-auto my-16">
        <ContractAddressHeader
          title={AccountData?.is_contract ? "Contract" : "Address"}
          address={address || ""}
        />
        <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row justify-between my-10">
          <ContractOverviewInfoCard data={AccountData} title={false} />
          <ContractOverviewInfoCard data={AccountData} title={true} />
        </div>
        <ul className="flex gap-12 items-center font-ModAberMano text-primaryLight p-8 font-medium">
          {menuItems.map((item) => (
            <li
              key={item.id}
              className={`cursor-pointer text-lg flex items-center gap-2 ${
                activeId === item.id && "border-b-2 border-primary"
              }`}
              onClick={() => handleTabChange(item.id, item.tab)}
            >
              {item.label}
              {item.img && (
                <span>
                  <img src={item.img} alt="" />
                </span>
              )}
            </li>
          ))}
          <li className="hidden ml-auto items-center gap-2 cursor-pointer">
            <span className="text-[9px]">FILTER</span>
            <span>
              <img className="w-[9px]" src={Images.filterHeaderIcon} alt="" />
            </span>
          </li>
        </ul>

        {/* Conditionally Render Content Based on Active Tab */}
        {activeId === 5 && AccountData?.is_contract ? (
          <ContractDetailsCard
            contractData={contractDetails}
            contractAddress={address as `0x${string}`}
            proxyContractAddress={
              contractDetails?.implementations.length
                ? (contractDetails?.implementations[0].address as `0x${string}`)
                : ""
            }
            proxyContractDetailsInfo={
              contractDetails?.implementations.length &&
              contractDetails?.implementations[0]?.address
                ? contractDetailsInfo
                : ""
            }
            contractDetailsInfo={contractDetails}
          />
        ) : (
          <>
            {activeId === 1 && transactionsLoading ? (
              <TransactionsTable
                transactionList={[]}
                isLoading={true}
                headerVisible={false}
              />
            ) : (
              activeId === 1 && (
                <TransactionsTable
                  transactionList={transactions.results}
                  isLoading={false}
                  headerVisible={false}
                />
              )
            )}
            {activeId === 2 && internalTxsLoading ? (
              <TransactionsTable
                transactionList={[]}
                isLoading={true}
                headerVisible={false}
              />
            ) : (
              activeId === 2 && (
                <TransactionsTable
                  transactionList={internalTxs?.results}
                  isLoading={false}
                  headerVisible={false}
                />
              )
            )}
            {activeId === 3 && tokenTransferTxsLoading ? (
              <TransactionsTable
                transactionList={[]}
                isLoading={true}
                headerVisible={false}
              />
            ) : (
              activeId === 3 && (
                <TransactionsTable
                  transactionList={tokenTransferTxs?.results}
                  isLoading={false}
                  headerVisible={false}
                />
              )
            )}
            {activeId === 4 && <DecodeEvent tx={logs?.items} />}
            {activeId !== 4 ? (
              <Pagination
                currentPage={currentPage}
                recordsPerPage={recordsPerPage}
                totalPages={transactions?.totalPages}
                totalResults={transactions?.totalResults}
                onPageChange={handlePageChange}
                onRecordsPerPageChange={handleRecordsPerPageChange}
              />
            ) : null}
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default TransactionSummery;
