import React from "react";
import MainLayout from "../layout/MainLayout";
import SearchBar from "../components/SearchBar";
import ContractList from "../components/ContractList";
import AdvancedFilter from "../components/AdvancedFilter";
import { Images, ReactIcons } from "../assets";
import { useNavigate } from "react-router-dom";

const SmartContractSearch: React.FC = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <SearchBar />

      <div className="max-w-6xl mx-auto min-h-screen">
        <div className=" flex items-center gap-2 my-10">
          <img src={Images.qpLogoIcon} alt="" />
          <span className="font-medium text-lg text-primaryLight">
            Smart Contract Search
          </span>
        </div>

        <div className="w-full p-10 border border-borderBackgroundLight rounded-lg space-y-10  font-ModAberMano bg-backgroundLight text-center">
          <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
            Search Smart Contract Source Codes On Etherscan And Filter By
            Contract & Deployer Addresses, Creation Date, Block Number And More.
          </p>
          <div className="flex items-center bg-backgroundDark rounded-md border border-backgroundLight p-4 max-w-lg mx-auto">
            <img
              className="w-6 mr-2"
              src={Images.contractSearchIcon}
              alt="Search Icon"
            />
            <input
              type="text"
              placeholder="Search Smart Contracts Source Codes"
              className="flex-grow bg-transparent text-gray-300 text-sm placeholder-gray-400 focus:outline-none px-2"
            />
            <ReactIcons.CiSearch
              onClick={() => navigate("/smarrt-contract/search")}
              className="text-primary text-2xl cursor-pointer"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default SmartContractSearch;
