import React from "react";
import { ResponsiveContainer, AreaChart, Tooltip, Area } from "recharts";

interface VolumeChartProps {
  data: { date: string; volume: number }[];
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const timestamp = payload[0].payload.date;
    // Convert the timestamp to a formatted date
    const date = new Date(timestamp).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Use 12-hour format with AM/PM
    });
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "5px 10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          fontSize: "12px",
        }}
      >
        <p>{`Time: ${date}`}</p>
        <p>{`Transactions: ${payload[0].value.toLocaleString()}`}</p>
      </div>
    );
  }
  return null;
};

const VolumeChart: React.FC<VolumeChartProps> = ({ data }) => {
  // Map data to match the format required by the chart
  const chartData = data.map((entry) => ({
    date: new Date(entry.date).getTime(), // Convert date to timestamp for better handling
    volume: entry.volume,
  }));

  return (
    <ResponsiveContainer width={300} height={60}>
      <AreaChart
        data={chartData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="volume"
          stroke="#ffffff"
          fill="#ffffff"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default VolumeChart;
