export const TransactionSkeleton: React.FC = () => {
  return (
    <div className="animate-pulse space-y-5">
      <div className="h-6 bg-background rounded w-1/3"></div>
      <div className="h-6 bg-background rounded w-full"></div>
      <div className="h-6 bg-background rounded w-1/2"></div>
      <div className="h-6 bg-background rounded w-full"></div>
      <div className="h-6 bg-background rounded w-1/4"></div>
    </div>
  );
};
